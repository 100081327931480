import LocalizedStrings from "react-localization";

const localizedTexts  = new LocalizedStrings({
    "de" : {
        "organistaionoverview" : "Vereinsübersicht",
        "oranisationdetailview": "Detailansicht",
    },
    "fr": {
        "organistaionoverview" : "Aperçu des sociétes",
        "oranisationdetailview": "Aperçu des détails",
    },
    "it": {
        "organistaionoverview" : "Panoramica dell'società",
        "oranisationdetailview": "Dettaglio",
    },
    "en": {
    }
});
export default localizedTexts;