import LocalizedStrings from "react-localization";

const localizedTexts  = new LocalizedStrings({
    "de" : {
        "title" : "Schiessanlagen",
        "headerNumber":"Nummer",
        "headerName":"Name",
        "headerCity":"Ort",
        "headerDiscipline":"Disziplin",
        "headerIsActive":"Anlage Aktiv",
        "headerActivate":"Anlage Aktivieren/Deaktivieren",
        "headerLanes":"Scheiben",
        "headerActions":"Aktionen",
        "buttonActivate":"Aktivieren",
        "buttonDeactivate":"Deaktivieren",
        "buttonSendParticipants":"Teilnehmer senden",
    },
    "fr": {
        "title" : "Stands de tir",
        "headerNumber":"nombre",
        "headerName":"nom",
        "headerCity":"lieu",
        "headerDiscipline":"discipline",
        "headerIsActive":"stand active",
        "headerActivate":"activer/désactiver stand",
        "headerLanes":"cibles",
        "headerActions":"actions",
        "buttonActivate":"Activer",
        "buttonDeactivate":"Désactiver",
        "buttonSendParticipants":"Envoyer les participants",
    },
    "it": {

    },
    "en": {

    }
});
export default localizedTexts;