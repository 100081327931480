import LocalizedStrings from "react-localization";

const localizedTexts  = new LocalizedStrings({
    "de" : {
        "title" : "Schnittstelle konfigurieren",
        "labelName":"Name",
        "labelCity":"Ort",
        "labelDescription":"Beschreibung",
        "labelId":"ID",
        "labelStreet":"Strasse",
        "labelZipCode": "PLZ",
        "labelSave":"Speichern",
        "labelCancel":"Abbrechen",
        "labelClose":"Schliessen",
        "labelDisciplines":"Disziplin",
        "labelTest":"Test",
        "labelProd":"Produktiv",
        "labelSelectOption":"Achtung, die folgenden Änderungen dürfen nicht während des Schiessbetriebs durcheführt werden.",
        "headerDisciplineName":"Diszipline",
        "headerLaneCount":"Anzahl Scheiben",
        "headerStartLaneNumber":"Erste Scheibennummer",
        "headerEndLaneNumber":"Letzte Scheibennummber",
        "headerRangeType":"Typ",
        "labelAddDiscipline":"Weitere Disziplin Hinzufügen",
        "lblSystemSwitch":"Test / Prod",
        "lblInterfaceSwitch":"Interface",
        "lblNameSwitch":"ID ändern",
        "lblUpdateSwitch":"Update",
        "titleEnvironment":"Umgebung",
        "buttonTriggerChange":"Änderung ausführen",
        "alertTextWaitForChange":"Die Änderung wurde zum Connector gesendet. Es kann bis zu 3 Minuten dauern, bis sie aktiv wird",
        "alertTextTransmissionFailed":"Fehler beim senden der Änderung, bitte erneut versuchen."
    },
    "fr": {
        "title" : "enregistrer un nouveaux stand de tir",
        "labelName":"nom",
        "labelCity":"lieu",
        "labelDescription":"description",
        "labelId":"ID",
        "labelStreet":"rue",
        "labelZipCode": "code postal",
        "labelSave":"enregistrer",
        "labelCancel":"annuler",
        "labelClose":"fermer",
        "labelDisciplines":"disciplines",
        "labelTest":"Test",
        "labelProd":"Productif",
        "labelSelectOption":"Attention, les modifications suivantes ne doivent pas être effectuées pendant les tirs.",
        "headerDisciplineName":"discipline",
        "headerLaneCount":"nombre de cibles",
        "headerStartLaneNumber":"premier nombre de cible",
        "headerEndLaneNumber":"dernier nombre de cible",
        "headerRangeType":"type",
        "labelAddDiscipline":"ajouter une autre discipline",
        "lblSystemSwitch":"Test / Prod",
        "lblInterfaceSwitch":"Interface",
        "lblNameSwitch":"Changer de id",
        "lblUpdateSwitch":"Update",
        "titleEnvironment":"énvironment",
        "buttonTriggerChange":"exécuter la modification",
        "alertTextWaitForChange":"La modification a été envoyée au connecteur. Cela peut prendre jusqu'à 3 minutes avant qu'elle ne devienne active ",
        "alertTextTransmissionFailed":"Erreur lors de l'envoi de la modification, veuillez réessayer."
    },
    "it": {

    },
    "en": {

    }
});
export default localizedTexts;