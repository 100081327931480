import React, {Component, useEffect, useState} from "react";
import {useParams} from "react-router";
import {Invoice} from "./models/Models";
import ApiClientFactory from "./models/ApiClientFactory";
import localizedTexts from "./texts/EventV2InvoiceOverview.texts";
import ToastMessage from "../common/components/ToastMessage";
import PrintDocument from "../common/Helpers/PrintDocument";


const EventV2InvoiceOverview = () => {
    const apiClient = ApiClientFactory.GetApiClient();

    const {eventId} = useParams();

    const [invoices, setInvoices] = useState<Invoice[]>([]);
    const [search, setSearch] = useState<string>("");

    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastBgClass, setToastBgClass] = useState('danger');

    useEffect(() => {
        loadData();

        const tooltipTriggerList = [].slice.call(
            document.querySelectorAll('[data-bs-toggle="tooltip"]')
        );
        tooltipTriggerList.forEach(function (tooltipEl) {
            // eslint-disable-next-line no-undef
            new (require('bootstrap').Tooltip)(tooltipEl);
        });
    }, [])

    async function loadData() {
            var invoices = await apiClient.api.eventsV2FinancialInvoiceDetail(`${eventId}`);
            if (invoices.ok) {
                setInvoices(invoices.data);
            }else{
                let errorCodeTranslation = localizedTexts.getString(`errorCode-${invoices.error?.errorCode}`);
                if (!invoices.bodyUsed) {
                    const contentType = invoices.headers.get('Content-Type');
                    if (contentType !== null && contentType.indexOf("application/json") > -1) {
                        invoices.json().then(d => {
                            errorCodeTranslation = localizedTexts.getString(`errorCode-${d?.errorCode}`);
                        });
                    }
                }
                if (!errorCodeTranslation) {
                    errorCodeTranslation = localizedTexts.errorGeneralText ?? "";
                }
                setToastMessage(errorCodeTranslation);
                setShowToast(true);
            }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value.toLowerCase());
    }

    async function printInvoice(invoice: Invoice) {
        const result = await apiClient.api.eventsV2DocumentInvoiceDetail(invoice.invoiceNr!, eventId ?? "");
        if (result.ok) {
            await PrintDocument.printPdfDocument(result);
        } else {
            setToastBgClass("danger");
            setShowToast(true);
            setToastMessage(localizedTexts.errorGeneralText ?? "");
            console.error('Failed to generate invoice document:', result.statusText);
        }
    }

    async function removeInvoice(invoice: Invoice) {
        try {
            const isConfirmed = window.confirm(localizedTexts.messageSureDeleteInvoice + "\n" + "Nr:" + invoice.invoiceNr + " " + invoice.amount + " CHF" );
            if (isConfirmed) {
                var result = await apiClient.api.eventsV2FinancialInvoiceDelete(invoice.invoiceNr!, `${eventId}`, invoice.organizationNumber ?? "")
                if (result.ok) {
                    setToastBgClass("success");
                    setToastMessage(localizedTexts.messageInvoiceDeletionSuccessful ?? "OK");
                    setShowToast(true);

                    await loadData()
                }

            }
        }
        catch (e: any)
        {
            setToastBgClass("danger");
            setToastMessage(localizedTexts.errorGeneralText ?? "");
            setShowToast(true);
        }
    }

    return <div>
        {showToast && (
            <div id="toast-container" className="position-fixed top-0 start-50 translate-middle-x p-3"
                 style={{zIndex: 11}}>
                <ToastMessage id="errorToast" message={toastMessage} onClose={() => setShowToast(false)} role="alert" bgClass={toastBgClass}/>
            </div>
        )}
        {invoices.length > 0 && (
            <div className="container" style={{maxWidth: '95%'}}>
                <h1><span className={"bi-card-list"}> </span>{localizedTexts.title}</h1>
                <div className="col-12 d-flex align-items-center justify-content-end">
                    <span className="h4 mt-1">{localizedTexts.labelSearch}: </span>&nbsp;&nbsp;
                    <input placeholder="suchen" onChange={handleChange}/>
                </div>
                <table className={"table table-striped table-responsive"}>
                    <thead>
                    <tr>
                        <th>{localizedTexts.headerInvoiceNr}</th>
                        <th>{localizedTexts.headerInvoiceDate}</th>
                        <th>{localizedTexts.headerInvoiceDueDate}</th>
                        <th className={"text-end"}>{localizedTexts.headerInvoiceAmount}</th>
                        <th>{localizedTexts.headerState}</th>
                        <th>{localizedTexts.headerOrganizationNr}</th>
                        <th>{localizedTexts.headerActions}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        invoices
                            .sort((a, b) => b.invoiceNr ? -(a.invoiceNr ?? 0) : 0)
                            .filter(invoice => {
                                if (search && search != "") {
                                    return invoice.invoiceNr?.toString().padStart(6, "0").toLowerCase().includes(search)
                                        || ((invoice.organizationNumber?.toLowerCase().indexOf(search.toLowerCase()) ?? -1) > -1)
                                }
                                return true;
                            })
                            .map((invoice: Invoice) => {
                                const isOverdue = (invoice.dueDate && new Date(invoice.dueDate) < new Date()) && invoice.invoiceStatusId == 2; // is only overdue if in open state
                                const isVoided = invoice.invoiceStatusId == 4;
                            return (
                                <tr key={invoice.invoiceNr} className={`${isOverdue ? "table-danger" : invoice.invoiceStatusId == 2 ? "table-info" : invoice.invoiceStatusId == 3 ? "table-success" :  ""} align-middle`}>
                                    <td>{invoice?.invoiceNr?.toString().padStart(6, "0")}</td>
                                    <td>{invoice.date ? new Date(invoice.date).toLocaleDateString() : "N/A"}</td>
                                    <td>{invoice.dueDate ? new Date(invoice.dueDate).toLocaleDateString() : "N/A"}</td>
                                    <td className={"text-end"} style={{textDecoration: isVoided ? "line-through" : "none"}}>{invoice?.amount} CHF</td>
                                    <td>{invoice.invoiceStatusName} {invoice.invoiceStatusId == 3 ? <span className={"bi-check-circle"}></span> : ""}</td>
                                    <td
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title={`${invoice.organizationName}.`}
                                    >
                                        {invoice.organizationNumber}
                                    </td>
                                    <td>
                                        <button className={'btn btn-primary'} onClick={() => printInvoice(invoice)}><span
                                            className={"bi-printer"}> </span></button>
                                        &nbsp;&nbsp;
                                        <button className={'btn btn-danger'} disabled={invoice.invoiceStatusId != 1} onClick={() => removeInvoice(invoice)}><span
                                            className={"bi-trash3-fill"}> </span></button>
                                    </td>
                                </tr>
                            );
                            })
                    }
                    </tbody>
                </table>
            </div>
        )}
    </div>
}

export default EventV2InvoiceOverview;

