import LocalizedStrings from 'react-localization';

const localizedTexts = new LocalizedStrings({
    "de": {
        "title": "Filter Parameter",
        "buttonApply": "Anwenden",
        "labelSelectDiscipline": "Disziplin wählen",
        "labelDiscipline": "Disziplin",
        "labelTimeslots": "Rangeure",
        "labelUnfixedTimeslots": "nicht fixierte Rangeure",
        "labelInvoiceState": "Rechnungsstatus",
        "labelSelectInvoiceState": "Status wählen",
        "textInvoiceStateOpen" : "offen",
        "textInvoiceStateOverdue": "überfällig",
    },
    "fr": {
        "title": "Paramètre de Filtrage",
        "buttonApply": "Appliquer",
        "labelSelectDiscipline": "Choisir la discipline",
        "labelDiscipline": "Discipline",
        "labelTimeslots": "Rangeurs",
        "labelUnfixedTimeslots": "Rangeurs non fixes",
        "labelInvoiceState": "Statut de la facture",
        "labelSelectInvoiceState": "Choisir le statut",
        "textInvoiceStateOpen": "ouverte",
        "textInvoiceStateOverdue": "en retard"
    },
    "it": {
        "title": "Parametro di Filtro",
        "buttonApply": "Applica",
        "labelSelectDiscipline": "Selezionare la disciplina",
        "labelDiscipline": "Disciplina",
        "labelTimeslots": "Rangeurs",
        "labelUnfixedTimeslots": "Rangeurs non fissi",
        "labelInvoiceState": "Stato della fattura",
        "labelSelectInvoiceState": "selezionare lo stato",
        "textInvoiceStateOpen": "apperto",
        "textInvoiceStateOverdue": "in ritardo"
    },
    "en": {
        "title": "Filter Parameter",
        "buttonApply": "Apply",
        "labelSelectDiscipline": "Select discipline",
        "labelDiscipline": "Discipline",
        "labelTimeslots": "Timeslots",
        "labelUnfixedTimeslots": "not fixed timeslots",
        "labelInvoiceState": "invoice state",
        "labelSelectInvoiceState": "Select state",
        "textInvoiceStateOpen": "open",
        "textInvoiceStateOverdue": "overdue"
    }
});

export default localizedTexts;