import LocalizedStrings from "react-localization";

const localizedTexts  = new LocalizedStrings({
    "de" : {
        "overview" : "Übersicht",
        "title" : "Vereinsübersicht",
        "labelSearch":"Suchen",
        "labelFilter":"Filter",

        "headerOrganization":"Verein",
        "headerOrganizationNumber":"Verein Nr.",
        "headerDiscipline":"Disziplin",
        "headerTimeSlot":"Rangeure",
        "headerGroups":"Gruppen",
        "headerInvoices":"Rechnungen",
        "headerLastEmail":"Letzte E-Mail",

        "textUnfixedRangeurs":"n.fix rangeure",
        "textOpenInvoices":"offene Rechnungen",
        "textOverdueInvoices":"überfäll. Rechnungen",

        "errorGeneralText":"Ein Fehler ist aufgetreten. Bitte nochmals versuchen.",
        "errorCode-ERRORORGANIZATIONNOTFOUND":"Verein nicht gefunden",
        "errorCode-NORIGHTS":"Keine Berechtigung.",
    },
    "fr": {
        "overview" : "Aperçu",
        "title" : "Aperçu des sociétes",
        "labelSearch":"Rechercher",
        "labelFilter":"filtre",

        "headerOrganization":"Sociéte",
        "headerOrganizationNumber":"N° de sociéte",
        "headerDiscipline":"Discipline",
        "headerTimeSlot":"Rangeurs",
        "headerGroups":"Groupes",
        "headerInvoices":"Factures",
        "headerLastEmail":"Dernier e-mail",

        "textUnfixedRangeurs":"n.fix rangeurs",
        "textOpenInvoices":"factures ouvertes",
        "textOverdueInvoices":"factures en retard",

        "errorGeneralText":"Une erreur s'est produite. Veuillez réessayer.",
        "errorCode-ERRORORGANIZATIONNOTFOUND":"Organisation introuvable",
        "errorCode-NORIGHTS":"Permission refusée.",
    },
    "it": {
        "overview" : "Panoramica",
        "title" : "Panoramica dell'società",
        "labelSearch":"Cerca",
        "labelFilter":"filtro",

        "headerOrganization":"Società",
        "headerOrganizationNumber":"N. società",
        "headerDiscipline":"Disciplina",
        "headerTimeSlot":"Fasce orarie",
        "headerGroups":"Gruppi",
        "headerInvoices":"Fatture",
        "headerLastEmail":"Ultima email",

        "textUnfixedRangeurs":"n.fissato rangeurs",
        "textOpenInvoices":"fatture aperte",
        "textOverdueInvoices":"fatture scadute",

        "errorGeneralText":"Si è verificato un errore. Riprova per favore.",
        "errorCode-ERRORORGANIZATIONNOTFOUND":"Organizzazione non trovata",
        "errorCode-NORIGHTS":"Autorizzazione negata.",
    },
    "en": {
        "overview" : "Overview",
        "title" : "Organization Overview",
        "textOpenInvoices":"open invoices",
        "textOverdueInvoices":"overdue invoices",

    }
    }
)
export default localizedTexts;