import LocalizedStrings from "react-localization";

const localizedTexts  = new LocalizedStrings({
    "de" : {
        "title" : "Rangier Übersicht",
        "headerShootingRange":"Schiessanlage",
        "headerDate":"Datum",
        "headerDistance":"Distanz",
        "headerOpenMorning":"Morgen Offene",
        "headerOpenAfternoon":"Nachmittag Offene",
        "headerBooked":"Gebuchte",
        "headerBlocked":"Blockierte",
        "headerTotal":"Total",
        "errorGeneralText":"Ein Fehler ist aufgetreten. Bitte nochmals versuchen."
    },
    "fr": {
    },
    "it": {

    },
    "en": {

    }
});
export default localizedTexts;