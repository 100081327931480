import React, {Component} from "react";
import {EFireKind, ShootingProgramDefinition, ShootingRange, TargetScoringType, TargetState} from "./models/Models";

import localizedTexts from "./texts/RangeAdministration.Text";
import {useParams} from "react-router";
import ApiClientFactory from "./models/ApiClientFactory";

function RangeShootingProgramsWrapper() {
    let {rangeId} = useParams();
    let {distanceId} = useParams();
    return <RangeShootingPrograms rangeId={rangeId} distanceId={parseInt(distanceId!)}/>
}


export class RangeShootingPrograms extends Component<RangeShootingProgramsStateProps, RangeShootingProgramsState> {
    apiClient = ApiClientFactory.GetApiClient();

    constructor(props: RangeShootingProgramsStateProps) {
        super(props);
        this.state = {
            shootingPrograms: [],
            isLoading: true,
            FireKindMap: {
                [EFireKind.Value0] : "",
                [EFireKind.Value1] : "P",
                [EFireKind.Value2] : "E",
                [EFireKind.Value3] : "S",
            },
            TargetScoringMap: {
                [TargetScoringType.Value1] : "LG10{'\u00A0'}{'\u00A0'}",
                [TargetScoringType.Value2] : "LG10.9",
                [TargetScoringType.Value3] : "LP10"  + '\u00A0' + '\u00A0',
                [TargetScoringType.Value4] : "LP10.9",
                [TargetScoringType.Value5] : "G10"  + '\u00A0' + '\u00A0' + '\u00A0',
                [TargetScoringType.Value6] : "G100"  + '\u00A0' + '\u00A0',
                [TargetScoringType.Value7] : "G10.9" + '\u00A0',
                [TargetScoringType.Value8] : "PP10"  + '\u00A0' + '\u00A0',
                [TargetScoringType.Value9] : "PA10"   + '\u00A0' + '\u00A0',
                [TargetScoringType.Value10] : "PA100"  + '\u00A0',
                [TargetScoringType.Value11] : "PB5"  + '\u00A0' + '\u00A0' + '\u00A0',
                [TargetScoringType.Value12] : "A5"  + '\u00A0' + '\u00A0' + '\u00A0' + '\u00A0',
                [TargetScoringType.Value13] : "A10" + '\u00A0' + '\u00A0' + '\u00A0',
                [TargetScoringType.Value14] : "A10.9" + '\u00A0',
                [TargetScoringType.Value15] : "A100" + '\u00A0' + '\u00A0',
                [TargetScoringType.Value16] : "B4" + '\u00A0' + '\u00A0' + '\u00A0' + '\u00A0',
                [TargetScoringType.Value17] : "B10"  + '\u00A0' + '\u00A0' + '\u00A0',
                [TargetScoringType.Value18] : "PB10"  + '\u00A0' + '\u00A0',
            }
        }
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        try {
            let shootingPrograms = await this.apiClient.api.corePublicRangeDataShootingProgramDefinitionDetail(`${this.props.rangeId}`, this.props.distanceId);
            if (shootingPrograms.ok) {
                this.setState({shootingPrograms: shootingPrograms.data, isLoading: false});
            }
        } catch (e: any) {

        }
    }



    render()      {
        if (this.state.isLoading){
            return <div>
                <h1>{localizedTexts.title}</h1>
                <h4>{localizedTexts.textSiteLoading}</h4>
            </div>
        }

    return <div className={"container-fluid"}>
        <h1>{localizedTexts.shootingPrograms}</h1>

        <div className={"card mt-3"}>
            <div className={"card-body"}>
                <h5 className={"card-text"}> Auf WinFire Connector erkannte Programme </h5>
                <table className={"table table-striped table-responsive"}>
                    <thead>
                    <tr>
                        <th className={"col-sm-1"}>{"Nr"}</th>
                        <th>{"Name"}</th>
                        <th>{"Sequenz"}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        this.state.shootingPrograms.map((p) => {
                            return <tr key={p.programNr}>
                                <td>{p.programNr}</td>
                                <td>{p.name}</td>
                                <td>{p.sequenceDefinitions?.map((seq, idx) =>
                                    <>
                                        <p style={{display:'inline'}}>{idx > 0 ? "," : ""} {this.state.TargetScoringMap[seq.programTargetScoringType ?? 1]}</p>
                                        <p style={{display:'inline', fontWeight:'bold'}}> {this.state.FireKindMap[seq.fireKind ?? 0] + seq.numberOfShots}</p>
                                    </>
                                )}</td>
                            </tr>
                        })
                    }
                    </tbody>
                </table>
            </div>
        </div>

    </div>

    }
    }

    interface RangeShootingProgramsState {
        isLoading: boolean;
        shootingPrograms: ShootingProgramDefinition[];
        FireKindMap: {[key in EFireKind]: string;}
        TargetScoringMap: {[key in TargetScoringType]: string;}

    }

    interface RangeShootingProgramsStateProps {
        rangeId: string | undefined
        distanceId: number
    }

    export default RangeShootingProgramsWrapper;