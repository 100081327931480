import LocalizedStrings from "react-localization";

const localizedTexts  = new LocalizedStrings({
    "de" : {
        "title" : "Rechnungen",
        "headerInvoiceNr" : "Rechnungsnummer",
        "headerInvoiceDate" : "Rechnungsdatum",
        "headerInvoiceDueDate" : "Fälligkeitsdatum",
        "headerInvoiceAmount" : "Betrag",
        "headerOrganizationNr" : "VereinsNr",
        "headerState" : "Status",
        "headerActions" : "Aktionen",
        "labelSearch" : "Suchen",
        "messageSureDeleteInvoice" : "Sind Sie sicher, dass Sie die Rechnung löschen wollen?",
        "messageInvoiceDeletionSuccessful" : "Rechnung gelöscht.",
        "errorGeneralText" : "Ein Fehler ist aufgetreten. Bitte nochmals versuchen."
    },
    "fr": {

    },
    "it": {

    },
    "en": {

    }
});
export default localizedTexts;