import {NavLink} from "react-router-dom";
import localizedTexts from "./texts/EventV2OrganizationManagement.texts";

const EventV2OrganizationManagementNavigation : React.FC = () => {

    return <div className="btn-group py-2" role="group" aria-label="Vertical button group">
        <NavLink to={"organisationoverview"} className="btn btn-outline-dark">
            <span className={"bi-people"}> </span>{localizedTexts.organistaionoverview}
        </NavLink>
        <NavLink to={"organisationdetail"} className="btn btn-outline-dark">
            <span className={"bi-people"}> </span>{localizedTexts.oranisationdetailview}
        </NavLink>
    </div>
}

export default EventV2OrganizationManagementNavigation;