import LocalizedStrings from "react-localization";

const localizedTexts  = new LocalizedStrings({
    "de" : {
        "timeslotreservation" : "Rangieren",
        "timeslotreservationoverview": "Rangierübersicht"
    },
    "fr": {
    },
    "it": {
    },
    "en": {
    }
});
export default localizedTexts;