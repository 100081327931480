import {useParams} from "react-router";
import React, { Component, ChangeEvent } from "react";
import localizedTexts from "./texts/EventManagementShootingRanges.texts";
import {ActivateShootingRangeCmd, ShootingRange} from "./models/Models";
import ApiClientFactory from "./models/ApiClientFactory";
import EventManagementShootingRangesAddRangeModal from "./EventManagementShootingRangesAddRangeModal";

function EventManagementShootingRangesWrapper(){
    let {eventId} = useParams();
    return <EventManagementShootingRanges eventId={eventId} />;
}

export class EventManagementShootingRanges extends Component<EventManagementShootingRangesProps, EventManagementShootingRangesState>{
    apiClient = ApiClientFactory.GetApiClient();

    constructor(props: EventManagementShootingRangesProps) {
        super(props);
        this.state = {
            shootingRanges: [],
            isSendParticipantsChecked: true
        }
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData(){
        try {
            var result = await this.apiClient.api.eventsEventManagementShootingrangesDetail(this.props.eventId??"");
            if (result.ok){
                this.setState({shootingRanges: result.data});
            }
        }catch(e:any){

        }
    }

    activateShootingRange = async (shootingRange: ShootingRange)=> {
        try {
            let cmd : ActivateShootingRangeCmd = {
                shootingRange: shootingRange,
                sendParticipants : this.state.isSendParticipantsChecked
            }
            var result = await this.apiClient.api.eventsEventManagementShootingrangesActivateCreate(this.props.eventId ?? "", cmd);
            if (result.ok) {
                this.loadData();
            }
        } catch (e: any) {

        }
    }

    deactivateShootingRange = async (shootingRange: ShootingRange)=> {
        try {
            var result = await this.apiClient.api.eventsEventManagementShootingrangesDeactivateCreate(this.props.eventId ?? "", shootingRange);
            if (result.ok) {
                this.loadData();
            }
        } catch (e: any) {

        }
    }

    removeShootingRange = async (shootingRange: ShootingRange) => {
        try {
            var result = await this.apiClient.api.eventsEventManagementShootingrangesRemoveDelete(shootingRange.disciplineId ?? 0, this.props.eventId ?? "", shootingRange.number ?? "");
            if (result.ok) {
                this.loadData();
            }
        } catch (e: any) {

        }
    }

    handleSendParticipantsChange = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({isSendParticipantsChecked: event.target.checked});
    }

    handleLaneChange = (number: string, laneType: 'laneFrom' | 'laneTo', event : ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const parsedValue = parseInt(value);

        this.setState((prevState) => ({
            shootingRanges: prevState.shootingRanges.map((range) =>
                range.number === number ? { ...range, [laneType]: parsedValue } : range
            ),
        }));
    }

    render(){
        return <div>
            <h1>{localizedTexts.title}</h1>
            <button className={"btn btn-primary"}
                    data-bs-toggle="modal"
                    data-bs-target="#shootingRangeList">
                    Anlage hinzufügen
            </button>
            <table className={"table table-striped table-responsive"}>
                <thead>
                <tr>
                    <th>{localizedTexts.headerNumber}</th>
                    <th>{localizedTexts.headerName}</th>
                    <th>{localizedTexts.headerCity}</th>
                    <th>{localizedTexts.headerDiscipline}</th>
                    <th>{localizedTexts.headerIsActive}</th>
                    <th>{localizedTexts.headerActivate}</th>
                    <th>{localizedTexts.headerLanes}</th>
                    <th>{localizedTexts.headerActions}</th>
                </tr>
                </thead>
                <tbody>
                {
                    this.state.shootingRanges.map(s => {
                        return <tr key={s.number! + s.disciplineId!}>
                            <td>{s.number}</td>
                            <td>{s.name}</td>
                            <td>{s.city}</td>
                            <td>{s.disciplineName}</td>
                            <td>{s.isActive ? <span className={"bi-check-circle-fill text-success"}> {s.shootingRangeType} </span> :
                                <span className={"bi-x-circle-fill text-danger"}> {s.shootingRangeType} </span>}
                            </td>
                            <td>{s.isActive ?

                                <button className={"btn btn-danger"}
                                        onClick={() => this.deactivateShootingRange(s)}>{localizedTexts.buttonDeactivate}</button>
                                :
                                <div>
                                    <button className={"btn btn-primary"}
                                            onClick={() => this.activateShootingRange(s)}>{localizedTexts.buttonActivate}
                                    </button>

                                    <div className="form-check form-switch"
                                         style={{display: 'inline-block', marginLeft: '15px'}}>
                                        <input className="form-check-input" type="checkbox" id={`flexSwitchCheckDefault${s.number}`}
                                               checked={this.state.isSendParticipantsChecked}
                                               onChange={this.handleSendParticipantsChange}/>
                                        <label className="form-check-label" htmlFor={`flexSwitchCheckDefault${s.number}`}>{localizedTexts.buttonSendParticipants}</label>
                                    </div>
                                </div>
                            }</td>
                            <td>
                                {
                                    s.shootingRangeType === "SIUS SA9005" || s.shootingRangeType === "Polytronic TG6000" ?
                                        <span>
                                            <input
                                                type="number"
                                                max="99"
                                                id={`laneFrom${s.number}`}
                                                value={s.laneFrom}
                                                onChange={(event) => this.handleLaneChange(s.number ?? "", 'laneFrom', event)}
                                                disabled={s.isActive}
                                                style={{width: '40px'}}
                                            />
                                            &nbsp;-&nbsp;
                                            <input
                                                type="number"
                                                max="99"
                                                id={`laneTo${s.number}`}
                                                value={s.laneTo}
                                                disabled={s.isActive}
                                                onChange={(event) => this.handleLaneChange(s.number ?? "", 'laneTo', event)}
                                                style={{width: '40px'}}/>
                                        </span>
                                        : <span></span>
                                }
                            </td>
                            <td>
                                <button className={'btn btn-danger'}
                                        onClick={() => this.removeShootingRange(s)}
                                        disabled={s.isActive}>
                                    <span className={"bi-trash3-fill"}> </span>
                                </button>
                            </td>
                        </tr>
                    })
                }
                </tbody>
            </table>
            <EventManagementShootingRangesAddRangeModal
                modalId={"shootingRangeList"}
                eventId={this.props.eventId}
                rangesAtEvent={this.state.shootingRanges}
                onClose={() =>this.loadData()}
            />

        </div>
    }
}

interface EventManagementShootingRangesState {
    shootingRanges: ShootingRange[];
    isSendParticipantsChecked: boolean;
}

interface EventManagementShootingRangesProps {
    eventId: string | undefined;
}

export default EventManagementShootingRangesWrapper;