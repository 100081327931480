import {NavLink} from "react-router-dom";
import localizedTexts from "./texts/EventV2ShootingRangeManagement.texts";

const EventV2ShootingRangeManagementNavigation : React.FC = () => {

    return <div className="btn-group py-2" role="group" aria-label="Vertical button group">
        <NavLink to={"timeslotreservationoverview"} className="btn btn-outline-dark">
            <span className={"bi-book"}> </span>{localizedTexts.timeslotreservationoverview}
        </NavLink>
        <NavLink to={"timeslotreservation"} className="btn btn-outline-dark">
            <span className={"bi-book"}> </span>{localizedTexts.timeslotreservation}
        </NavLink>
    </div>
}

export default EventV2ShootingRangeManagementNavigation;