import {NavLink} from "react-router-dom";
import localizedTexts from "./texts/EventV2Navigation.texts";

const EventV2Navigation : React.FC = () => {

    return  <div className="btn-group py-2" role="group" aria-label="Vertical button group">
        <NavLink to={"overview"} className="btn btn-outline-dark">
            <span className={"bi-book"}> </span>{localizedTexts.overview}
        </NavLink>
        <NavLink to={"organisationmangement"} className="btn btn-outline-dark">
            <span className={"bi-people"}> </span>{localizedTexts.organistaionoverview}
        </NavLink>
        <NavLink to={"shootingrangemanagement/"} className="btn btn-outline-dark">
            <span className={"bi-book"}> </span>{localizedTexts.shootingRangeManagement}
        </NavLink>
        <NavLink to={"financialmanagement/"} className="btn btn-outline-dark">
            <span className={"bi-cash-coin"}> </span>{localizedTexts.financialManagement}
        </NavLink>
    </div>
}

export default EventV2Navigation;