import LocalizedStrings from "react-localization";

const localizedTexts  = new LocalizedStrings({
    "de" : {
        "title" : "Schiessanlagen",
        "settings": "Einstellungen",
        "textSiteLoading": "Seite lädt. Bitte einen Moment Geduld haben.",
        "eventSettingsDescription" : "Diese Einstellungen werden beim Deaktivieren eines Events programmiert",
        "save" : "Speichern",
        "nosettingsmanuelmode" : "Für manuelle Anlagen sind keine weitere Einstellungen verfügbar",
        "shooterNrMandatory" : "Schützennummer notwendig",
        "shooterNrRepeated" : "Schützennummer repetieren",
        "enableDemoMode" : "Demo Mode Ein",
        "disableDemoMode" : "Demo Mode Aus",
    },
    "fr": {
        "title" : "stand de tir",
        "settings" : "paramètres",
        "textSiteLoading": "La page est en cours de chargement. Veuillez patienter un instant.",
        "eventSettingsDescription" : "Ces paramètres sont programmés lors de la désactivation d'un événement",
        "save" : "enregistrer",
        "nosettingsmanuelmode" : "Aucun autre paramètres n'est disponible pour le mode manuel",
    },
    "it": {

    },
    "en": {

    }
});
export default localizedTexts;