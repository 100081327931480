import React, {useState, ChangeEvent, FormEvent, useEffect} from 'react';
import localizedTexts from "../texts/OrganizationFilterModal.texts";
import {Discipline} from "../models/Models";

export interface FilterOptions {
    disciplineId?: number;
    group?: string;
    unfixedTimeSlotCount?: boolean;
    invoiceStateId?: number;
}

interface OrganizationFilterProps {

    onApplyFilters: (filters: FilterOptions) => void;
    disciplines: Discipline[];

    filterCriteria: FilterOptions;
}

const OrganizationFilterModal: React.FC<OrganizationFilterProps> = ({disciplines,  onApplyFilters, filterCriteria}) => {
    const [localFilterCriteria, setLocalFilterCriteria] = useState<FilterOptions>(
        filterCriteria
    );

    useEffect(() => {
        setLocalFilterCriteria(filterCriteria);
    }, [filterCriteria]);

    function handleApplyFilters()
    {
        onApplyFilters(localFilterCriteria)
    }

    const handleFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = event.target;
        setLocalFilterCriteria((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLocalFilterCriteria((prev) => ({
            ...prev,
            [event.target.name]: event.target.checked
        }));
    };

    return (
        <div className="modal fade"
           id="OrganizationFilterModal"
           tabIndex={-1}
           aria-labelledby="OrganizationFilterModalLabel"
           aria-hidden="true">
          <div className="modal-dialog">
              <div className="modal-content">

                  <div className="modal-header">
                      <h5 className="modal-title" id="paymentFileUploadModalLabel">{localizedTexts.title}</h5>
                      <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                      ></button>
                  </div>

                  <div className="modal-body">
                      <form className="container-flex">
                          <div className={"row mb-4"}>
                              <div className={"col-6"}>
                                  <label>{localizedTexts.labelDiscipline}</label>
                              </div>

                              <div className={"col-6"}>
                                  <select
                                      name="disciplineId"
                                      value={localFilterCriteria.disciplineId}
                                      onChange={handleFilterChange}
                                      className="form-select"
                                  >
                                      <option value="">{localizedTexts.labelSelectDiscipline}</option>
                                      {disciplines.map((discipline, index) => (
                                          <option key={index} value={discipline.id}>
                                              {discipline.name}
                                          </option>
                                      ))}
                                  </select>
                              </div>
                          </div>
                          <div className={"row mb-4"}>
                              <div className={"col-6"}>
                                  <label>{localizedTexts.labelTimeslots}</label>
                              </div>

                              <div className={"col-6"}>
                                  <div className="form-check">
                                      <input className="form-check-input" type="checkbox" id="unfixedTimeSlotCount" name="unfixedTimeSlotCount" checked={localFilterCriteria.unfixedTimeSlotCount ?? false} onChange={handleCheckBoxChange}/>
                                      <label className="form-check-label" htmlFor="flexCheckDefault">
                                          {localizedTexts.labelUnfixedTimeslots}
                                      </label>
                                  </div>
                              </div>
                          </div>
                          <div className={"row mb-4"}>
                              <div className={"col-6"}>
                                  <label>{localizedTexts.labelInvoiceState}</label>
                              </div>

                              <div className={"col-6"}>
                                  <select
                                      name="invoiceStateId"
                                      value={localFilterCriteria.invoiceStateId}
                                      onChange={handleFilterChange}
                                      className="form-select"
                                  >
                                      <option value="">{localizedTexts.labelSelectInvoiceState}</option>
                                      <option key={"invoiceState_open"} value={2}>
                                          {localizedTexts.textInvoiceStateOpen}
                                      </option>
                                      <option key={"invoiceState_overdue"} value={4}>
                                          {localizedTexts.textInvoiceStateOverdue}
                                      </option>

                                  </select>
                              </div>
                          </div>
                          <div className={"row mb-2"}>
                              <div className={"col-2"}>
                              <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => handleApplyFilters()}>{localizedTexts.buttonApply}</button>
                              </div>
                          </div>
                      </form>
                  </div>
              </div>
          </div>
        </div>
    );
};

export default OrganizationFilterModal;