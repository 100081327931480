import React, {ReactElement, useEffect, useState} from "react";
import ToastMessage from "../common/components/ToastMessage";
import localizedTexts from "./texts/EventV2TimeSlotReservation.texts";
import WinFireDropDown, {WinFireDropDownElement} from "../common/components/WinFireDropDown";
import FilterableDatePicker from "./components/FilterableDatePicker";
import ApiClientFactory from "./models/ApiClientFactory";
import {useParams} from "react-router";
import {
    Discipline, Distance, EventInfo, EventRegistrationInformation, Organization,
    OrganizationTimeSlotInformation, OrganizationWithTimeSlotInformation, ShootingProgramDefinition,
    ShootingRange,
    ShootingRangeTimeSlotReservationInformations, TimeSlotReservationData, TimeSlotReservationInformation
} from "./models/Models";


const EventV2TimeSlotReservation: React.FC = () => {
    const apiClient = ApiClientFactory.GetApiClient();

    const {eventId} = useParams();

    const [eventInfo, setEventInfo] = useState<EventRegistrationInformation|undefined>();
    const [eventDates, setEventDates] = useState<Date[]>([]);

    const [shootingRanges, setShootingRanges] = useState([] as ShootingRange[]);
    const [selectedShootingRange, setSelectedShootingRange] = useState({} as ShootingRange);
    const [selectedDate, setSelectedDate] = useState(undefined as string | undefined);

    const [organizations, setOrganizations] = useState([] as Organization[]);
    const [selectedOrganization, setSelectedOrganization] = useState({} as Organization);

    const [distances, setDistances] = useState([] as Distance[]);
    const [selectedDistance, setSelectedDistance] = useState({} as Distance);
    const [isMorningSelected, setIsMorningSelected] = useState(true);
    const [isFullDayView, setIsFullDayView] = useState(false);

    const [fromTimeSlots, setFromTimeSlots] = useState([] as number[]);
    const [toTimeSlots, setToTimeSlots] = useState([] as number[]);

    const [timeSlotDatas, setTimeSlotDatas] = useState<TimeSlotReservationData>();

    const [notLockedTimeSlotinfo, setNotLockedTimeSlotInfo] = useState<TimeSlotReservationInformation[]>([]);

    const [showToast, setShowToast] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        loadData();
        loadAllRegisteredOrganizations();
        loadNotLockedCounts();
        setIsFullDayView(true);
    }, []);

    useEffect(() => {
        console.log("Updated eventDates:", eventDates);
    }, [eventDates]);


    async function loadData() {
        const eventInfoResult = await apiClient.api.eventsV2EventInformationRegistrationinformationDetail(`${eventId}`);
        if (eventInfoResult.ok){
            setEventInfo(eventInfoResult.data);
        }else{
            setErrorMessage(localizedTexts.errorGeneralText ?? "");
            setShowToast(true);
        }

        const shootingRangesResult = await apiClient.api.eventsV2ShootingRangeManagementDetail(`${eventId}`);
        let selectedShootingRange: ShootingRange | undefined = undefined;
        if (shootingRangesResult.ok) {
            setShootingRanges(shootingRangesResult.data);
            if (shootingRangesResult.data.length > 0) {
                selectedShootingRange = shootingRangesResult.data[0]
                setSelectedShootingRange(selectedShootingRange);
                setDistances(shootingRangesResult.data[0].distances ?? []);
                if (shootingRangesResult.data[0].distances) {
                    setSelectedDistance(shootingRangesResult.data[0].distances[0]);
                }
            }
        } else {
            setErrorMessage(localizedTexts.errorGeneralText ?? "");
            setShowToast(true);
        }

        const dateInfoResult = await apiClient.api.eventsV2ShootingRangeManagementTargetreservationtimeslotsDateinfoDetail(`${eventId}`)
        if (dateInfoResult.ok)
        {
            const eventDates : Date[] = dateInfoResult.data
                .map(d => new Date(d));
            setEventDates(eventDates);
        }
        else
        {
            setErrorMessage(localizedTexts.errorGeneralText ?? "");
            setShowToast(true);
        }
        await loadNotLockedCounts();
    }

    async function loadNotLockedCounts() {
        const notLockedResult = await apiClient.api.eventsV2ShootingRangeManagementTargetreservationtimeslotsStateinfoDetail(`${eventId}`);
        if (notLockedResult.ok) {
            setNotLockedTimeSlotInfo(notLockedResult.data);
        }
    }

    function selectRange(shootingRange: ShootingRange) {
        setSelectedShootingRange(shootingRange);
        setDistances(shootingRange.distances ?? []);
        if (shootingRange.distances) {
            setSelectedDistance(shootingRange.distances[0]);
            if (selectedDate) {
                loadTimeSlotInfo(shootingRange.number ?? "", selectedDate, shootingRange .distances[0].id);
            }
        }
    }

    function selectDate(date: string) {
        setSelectedDate(date)
        if (selectedShootingRange) {
            loadTimeSlotInfo(selectedShootingRange.number ?? "", date, selectedDistance.id);
        }
    }

    function setFromNotLocked(date: string, shootingRangeNumber: string, distanceId: number, isMorning: boolean) {
        const shootingRange = shootingRanges.find(sr => sr.number == shootingRangeNumber);
        if (shootingRange) {
            setIsMorningSelected(isMorning);
            setSelectedDate(date);
            setSelectedShootingRange(shootingRange);
            loadTimeSlotInfo(shootingRange.number ?? "", date, distanceId);
        }
    }

    async function loadTimeSlotInfo(shootingRange: string, date: string, distanceId: number = 0) {
        const timeSlotData = await apiClient.api.eventsV2ShootingRangeManagementTargetreservationtimeslotdatasDetail(shootingRange, date, distanceId, `${eventId}`);
        if (timeSlotData.ok) {
            setTimeSlotDatas(timeSlotData.data);
            setFromTimeSlots([]);
            setToTimeSlots([]);
        } else {
            setErrorMessage(localizedTexts.errorGeneralText ?? "");
            setShowToast(true);
        }
    }

    async function loadAllRegisteredOrganizations() {
        const result = await apiClient.api.eventsV2OrganizationsRegisteredDetail(`${eventId}`);
        if (result.ok) {
            setOrganizations(result.data);
        } else {
            setErrorMessage(localizedTexts.errorGeneralText ?? "");
            setShowToast(true);
        }
    }

    function selectOrganization(organization: Organization) {
        setSelectedOrganization(organization);
    }

    function selectDistance(distance: Distance) {
        setSelectedDistance(distance);
        loadTimeSlotInfo(selectedShootingRange?.number ?? "", selectedDate ?? "", distance.id);
    }

    async function switchEntries() {
        const result = await apiClient.api.eventsV2ShootingRangeManagementTargetreservationtimeslotsSwapCreate(`${eventId}`, {
            fromSlotIds: fromTimeSlots,
            toSlotIds: toTimeSlots
        });
        if (result.ok) {
            await loadTimeSlotInfo(selectedShootingRange.number!, selectedDate!, selectedDistance.id);
        } else {
            setErrorMessage(localizedTexts.errorGeneralText ?? "");
            setShowToast(true);
        }
    }

    async function changeShootingProgramDefinitionId(ids: number[], shootingProgramDefinitionId: number){
        const result = await apiClient.api.eventsV2ShootingRangeManagementTargetreservationtimeslotsChangeshoootingprogramdefinitionCreate(shootingProgramDefinitionId, `${eventId}`, ids);
        if (result.ok) {
            await loadTimeSlotInfo(selectedShootingRange.number!, selectedDate!, selectedDistance.id);
        } else {
            setErrorMessage(localizedTexts.errorGeneralText ?? "");
            setShowToast(true);
        }
    }

    function switchMorningAfternoon(isMorning: boolean) {
        setIsMorningSelected(isMorning);
        if (selectedShootingRange && selectedDate) {
            switchFullDayView(isFullDayView, isMorning);
        }
    }

    async function lockSelectedOrganization() {
        if (selectedOrganization.number && selectedShootingRange.number && selectedDate && selectedDistance) {
            await apiClient.api.eventsV2ShootingRangeManagementTargetreservationtimeslotsLockDetail(selectedOrganization.number, selectedShootingRange.number, selectedDistance.id!, selectedDate, isMorningSelected, `${eventId}`);
            loadTimeSlotInfo(selectedShootingRange.number ?? "", selectedDate ?? "", selectedDistance.id);
        }
    }

    async function resetStatus() {
        if (fromTimeSlots.length > 0) {
            const result = await apiClient.api.eventsV2ShootingRangeManagementTargetreservationtimeslotsResetstateCreate(`${eventId}`, fromTimeSlots);
            if (result.ok) {
                await loadTimeSlotInfo(selectedShootingRange.number!, selectedDate!, selectedDistance.id);
            }
        }
    }

    function selectOrganizationTimeSlots(laneNumber: number) {
        timeSlotDatas?.times?.forEach(tm => {
            tm.groups?.forEach(gr => {
                gr.items?.filter(it => (laneNumber == 0 || laneNumber == it.laneNumber) && it.assignedOrganizationNumber == selectedOrganization.number && (isFullDayView ? true : it.isMorning == isMorningSelected)).forEach(tsr => {
                    setFromTimeSlots(from => {
                        let ret = [];
                        ret.push(...from);
                        if (from.findIndex(f => f == tsr.id!) == -1) {
                            ret.push(tsr.id!);
                        }
                        return ret;
                    });
                })
            })
        })
    }

    function switchFullDayView(isFullDay: boolean, isMorning?: boolean | undefined) {
        setIsFullDayView(isFullDay);
    }


    return <div>
        {showToast && (
            <div id="toast-container" className="position-fixed top-0 start-50 translate-middle-x p-3"
                 style={{zIndex: 11}}>
                <ToastMessage id="errorToast" message={errorMessage} onClose={() => setShowToast(false)} role="alert"/>
            </div>
        )}
        <h1>{localizedTexts.title}</h1>
        <div className={"card mb-3"}>
            <div className={"card-header"}>
                <h5>{localizedTexts.labelSelection}</h5>
            </div>
            <div className={"card-body"}>
                <div className={"row"}>
                    <div className={"col-2"}>
                        <label>{localizedTexts.labelShootingRange}:</label> <WinFireDropDown
                        buttonText={`${selectedShootingRange?.name} ${selectedShootingRange?.number}`}
                        elements={!shootingRanges ? [] : shootingRanges.map(sr => {
                            return {
                                key: sr.number,
                                text: `${sr?.name} ${sr?.number}`,
                                sourceObject: sr,
                                selectFunction: selectRange
                            } as WinFireDropDownElement
                        })} elementFilter={() => true}/>
                    </div>
                    <div className={"col-1"}>
                        <label>{localizedTexts.labelDistance}:</label> <WinFireDropDown
                        buttonText={selectedDistance?.name ?? ""}
                        elements={!distances ? [] : distances.map(d => {
                            return {
                                key: d.name,
                                text: d.name,
                                sourceObject: d,
                                selectFunction: selectDistance
                            } as WinFireDropDownElement
                        })}
                        elementFilter={() => true}/>
                    </div>
                    <div className={"col-3"}>
                        <div className={"row"}>
                            <div className={"col-auto"}>
                                <label className={"form-label"}
                                       htmlFor={"datePicker"}>{localizedTexts.labelDate}:</label>
                            </div>
                            <div className={"col-auto"}>
                                {
                                    <FilterableDatePicker allowedDateList={eventDates!} onDateChange={(date: string) => { selectDate(date); }}/>
                                }
                            </div>
                            <div className={"col-auto"}>
                                <div className="form-check form-switch">
                                    <label className="form-check-label"
                                           htmlFor="flexSwitchCheckDefault1">{localizedTexts.labelMorning}</label>
                                    <input className="form-check-input" type="checkbox" role="switch"
                                           id="flexSwitchCheckDefault1"
                                           checked={isMorningSelected}
                                           onChange={(evt) => {
                                               if (evt && evt.currentTarget) {
                                                   switchMorningAfternoon(evt.currentTarget.checked);
                                               }
                                           }}/></div>
                            </div>
                            <div className={"col-auto"}>
                                <div className="form-check form-switch">
                                    <label className="form-check-label"
                                           htmlFor="flexSwitchCheckDefault1">{localizedTexts.labelAllDay}</label>
                                    <input className="form-check-input" type="checkbox" role="switch"
                                           id="flexSwitchCheckDefault1"
                                           checked={isFullDayView}
                                           onChange={(evt) => {
                                               if (evt && evt.currentTarget) {
                                                   switchFullDayView(evt.currentTarget.checked);
                                               }
                                           }}/></div>
                            </div>
                        </div>
                    </div>

                    <div className={"col-4"}>
                        <div className={"row"}>
                            <div className={"col-auto"}>
                                <label>{localizedTexts.labelOrganization}:</label> <WinFireDropDown
                                buttonText={selectedOrganization.name ?? ""}
                                elements={organizations.map(o => {
                                    return {
                                        key: o.number,
                                        text: o.name,
                                        sourceObject: o,
                                        selectFunction: selectOrganization
                                    } as WinFireDropDownElement
                                })}
                                elementFilter={(name: string, obj: Organization) => (obj.name?.toLowerCase().indexOf(name?.toLowerCase()) ?? 0) > -1}/>
                            </div>
                            <div className={"col-auto"}>
                                <button className={"btn btn-primary"} onClick={() => {
                                    lockSelectedOrganization()
                                }} disabled={selectedOrganization.number == undefined}><span
                                    className={"bi-check2-circle"}/> {localizedTexts.labelLockOrganization}</button>
                            </div>
                            <div className={"col-auto"}>
                                <button className={"btn btn-primary"} onClick={() => {
                                    selectOrganizationTimeSlots(0)
                                }} disabled={selectedOrganization.number == undefined}><span
                                    className={"bi-save"}/> {localizedTexts.labelSelectOrganizationSlots}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={"col-2"}>
                        <button className={"btn btn-primary"} data-bs-toggle="modal"
                                data-bs-target="#NotLockedTimeSlotOverview">{localizedTexts.labelShowNotLockedOverview}</button>
                    </div>
                </div>
            </div>
        </div>

        <div className={"card mb-3"}>
            <div className={"card-header"}>
                <h5>{localizedTexts.labelSwitchEntries}</h5>
            </div>
            <div className={"card-body"}>
                <div className={"row"}>
                    <div className={"col-1"}>
                        <button className={"btn btn-primary"}
                                disabled={fromTimeSlots.length == 0 || fromTimeSlots.length != toTimeSlots.length}
                                onClick={() => {
                                    switchEntries()
                                }}>{localizedTexts.labelSwitchEntries}</button>
                    </div>
                    <div className={"col-1"}>
                        {localizedTexts.labelFrom} : {fromTimeSlots.length}
                        <button className={"btn btn-primary ms-2"} onClick={() => resetStatus()}
                                disabled={fromTimeSlots.length == 0}>{localizedTexts.labelResetState}</button>
                    </div>
                    <div className={"col-1"}>
                        {localizedTexts.labelTo} : {toTimeSlots.length}
                    </div>
                    <div className={"col-1"}>
                        <button className={"btn btn-primary"}
                                onClick={() => {
                                    setToTimeSlots([]);
                                    setFromTimeSlots([]);
                                }}>{localizedTexts.labelClear}</button>
                    </div>
                </div>

            </div>
        </div>

        <table className={"table table-striped table-row-segregation"}>
            <thead>
            <tr>
                <th></th>
                {
                    (timeSlotDatas?.times && timeSlotDatas?.times.length > 0) ? timeSlotDatas.times[0].groups?.map(g => {

                        const elements = g.items?.map(i => {
                            return <th className={"sticky"}>{localizedTexts.headerLaneNumber} {i.laneNumber}
                                <div className="">
                                    <button className={"btn btn-primary mt-1"} onClick={() => {
                                        timeSlotDatas?.times?.forEach(tm => {
                                            tm.groups?.forEach(gr => {
                                                gr.items?.filter(it => it.laneNumber == i.laneNumber && (isFullDayView ? true : it.isMorning == isMorningSelected)).forEach(tsr => {
                                                    setFromTimeSlots(from => {
                                                        let ret = [];
                                                        ret.push(...from);
                                                        if (from.findIndex(f => f == tsr.id!) == -1) {
                                                            ret.push(tsr.id!);
                                                        }
                                                        return ret;
                                                    });
                                                })
                                            })
                                        })
                                    }}><span className={"bi bi-arrow-up"}/></button>

                                    <button className={"btn btn-primary mt-1 ms-1"} onClick={() => {
                                        selectOrganizationTimeSlots(i.laneNumber ?? 0);
                                    }}><span className={"bi bi-save"}/></button>

                                    <button className={"btn btn-primary mt-1 ms-1"} onClick={() => {
                                        timeSlotDatas?.times?.forEach(tm => {
                                            tm.groups?.forEach(gr => {
                                                gr.items?.filter(it => it.laneNumber == i.laneNumber && (isFullDayView ? true : it.isMorning == isMorningSelected)).forEach(tsr => {
                                                    setToTimeSlots(to => {
                                                        let ret = [];
                                                        ret.push(...to);
                                                        if ((fromTimeSlots.length == 0 || to.length < fromTimeSlots.length) && to.findIndex(f => f == tsr.id!) == -1) {
                                                            ret.push(tsr.id!);
                                                        }
                                                        return ret;
                                                    });
                                                })
                                            })
                                        })
                                    }}><span className={"bi bi-arrow-down"}/></button>

                                    <button className={"btn btn-primary mt-1 ms-1"} onClick={() => {
                                        timeSlotDatas?.times?.forEach(tm => {
                                            tm.groups?.forEach(gr => {
                                                gr.items?.filter(it => it.laneNumber == i.laneNumber && (isFullDayView ? true : it.isMorning == isMorningSelected)).forEach(tsr => {
                                                    setToTimeSlots(to => {
                                                        let ret = [];
                                                        ret.push(...to);
                                                        if ((fromTimeSlots.length == 0 || to.length < fromTimeSlots.length) && tsr.assignedOrganizationNumber == undefined && to.findIndex(f => f == tsr.id!) == -1) {
                                                            ret.push(tsr.id!);
                                                        }
                                                        return ret;
                                                    });
                                                })
                                            })
                                        })
                                    }}><span className={"bi bi-arrow-down-circle-fill"}/></button>
                                </div>
                            </th>
                        })

                        if (g.groupId != 0) {
                            elements?.unshift(<th className={"border-start border-black sticky"}>{localizedTexts.headerShootingProgramDefinition}</th>)
                        }

                        return elements;
                    }) : <span></span>
                }
            </tr>
            </thead>
            <tbody>
            {
                timeSlotDatas?.times?.filter(t => isFullDayView ? true : isMorningSelected == t.isMorning).map(t => {
                    return <tr>
                        <td>{t.time}</td>
                        {
                            t.groups?.map(g => {
                                var items = g.items?.map(tsr => {
                                    let addCssClass = tsr.stateId == 1 ? "bi-check-circle-fill text-success " : "bi-x-circle-fill text-danger "
                                    let infoData = tsr.stateId == 1 ? "" : `(${tsr.id})`
                                    let selectedTrueCssClass = selectedOrganization && selectedOrganization.number == tsr.assignedOrganizationNumber ? "bg-warning-subtle " : " ";
                                    if (tsr.stateId == 3) {
                                        addCssClass = "bi-check2-circle text-success";
                                        selectedTrueCssClass += "bg-warning ";
                                        infoData = "";
                                    }
                                    if (!tsr.assignedOrganizationNumber || tsr.assignedOrganizationNumber == '') {
                                        selectedTrueCssClass = "bg-info";
                                    }

                                    return <td className={selectedTrueCssClass}>
                                    <span
                                        className={"bi " + addCssClass}></span>{tsr.shootingProgramDefinitionName ?
                                        <span> {tsr.shootingProgramDefinitionName}<br/></span> : ""} {infoData} {organizations.find(o => o.number == tsr.assignedOrganizationNumber)?.name}<br/>{tsr.participantName}
                                        <div className="form-check form-switch">
                                            <label className="form-check-label"
                                                   htmlFor="flexSwitchCheckDefault1">{localizedTexts.labelFrom}</label>
                                            <input className="form-check-input" type="checkbox" role="switch"
                                                   id="flexSwitchCheckDefault1"
                                                   checked={fromTimeSlots.findIndex(f => f == tsr.id) > -1}
                                                   onChange={(evt) => {
                                                       if (evt && evt.currentTarget) {
                                                           setFromTimeSlots(from => {
                                                               let ret = [];
                                                               if (evt.currentTarget.checked) {
                                                                   ret.push(...from);
                                                                   ret.push(tsr.id!);
                                                               } else {
                                                                   ret = from.filter(f => f !== tsr.id!);
                                                               }
                                                               return ret;
                                                           });
                                                       }
                                                   }}/></div>
                                        <div className="form-check form-switch">
                                            <label className="form-check-label"
                                                   htmlFor="flexSwitchCheckDefault2">{localizedTexts.labelTo}</label>
                                            <input className="form-check-input" type="checkbox" role="switch"
                                                   id="flexSwitchCheckDefault2"
                                                   checked={toTimeSlots.findIndex(f => f == tsr.id) > -1}
                                                   onChange={(evt) => {
                                                       if (evt && evt.currentTarget) {
                                                           setToTimeSlots(to => {
                                                               let ret = [];
                                                               if (evt.currentTarget.checked) {
                                                                   ret.push(...to);
                                                                   ret.push(tsr.id!);
                                                               } else {
                                                                   ret = to.filter(f => f !== tsr.id!);
                                                               }
                                                               return ret;
                                                           });
                                                       }
                                                   }}/></div>
                                    </td>
                                });

                                if (g.groupId != 0) {
                                    items?.unshift(<td className={"border-start border-black"}>
                                        <WinFireDropDown buttonText={g.shootingProgramDefinitionName??""} elements={eventInfo!.disciplines!.filter(d => d.distance?.id == selectedDistance.id).flatMap(d => d.shootingProgramDefinitions!.map(spd => {return {
                                            key:spd.name,
                                            sourceObject:spd,
                                            text: `${d.name} ${spd.name}`,
                                            selectFunction: (obj:ShootingProgramDefinition) => {changeShootingProgramDefinitionId(g.items!.map(i => i.id!), spd.id??0)}
                                        } as WinFireDropDownElement}))} elementFilter={(text:string, obj:ShootingProgramDefinition) => (obj.name?.indexOf(text)??-1) > -1} /><br />
                                        <button className={"btn btn-primary mt-1"} onClick={() => {
                                            g.items?.forEach(it => {
                                                setFromTimeSlots(from => {
                                                    let ret = [];
                                                    ret.push(...from);
                                                    if (from.findIndex(f => f == it.id!) == -1) {
                                                        ret.push(it.id!);
                                                    }
                                                    return ret;
                                                });
                                            })
                                        }}><span className={"bi bi-arrow-up"}/></button>
                                        <button className={"btn btn-primary mt-1 ms-1"} onClick={() => {
                                            g.items?.forEach(it => {
                                                setToTimeSlots(to => {
                                                    let ret = [];
                                                    ret.push(...to);
                                                    if (to.findIndex(f => f == it.id!) == -1) {
                                                        ret.push(it.id!);
                                                    }
                                                    return ret;
                                                });
                                            })
                                        }}><span className={"bi bi-arrow-down"}/></button>
                                    </td>)
                                }

                                return items;
                            })
                        }
                    </tr>
                })
            }
            </tbody>
        </table>

        <div id="NotLockedTimeSlotOverview" className="modal fade modal-xl" tabIndex={-1}
             aria-labelledby="Create New Event" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title"
                            id="newEventModalLabel">{localizedTexts.labelShowNotLockedOverview}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className={"shadow p-2 rounded"}>
                            <button className={"btn btn-primary"} onClick={() => {
                                loadNotLockedCounts()
                            }}>{localizedTexts.labelReload}</button>
                            <table className={"table table-striped"}>
                                <thead>
                                <tr>
                                    <th>{localizedTexts.labelDate}</th>
                                    <th>{localizedTexts.labelMorning}</th>
                                    <th>{localizedTexts.labelShootingRange}</th>
                                    <th>{localizedTexts.labelDistance}</th>
                                    <th className={"text-end"}>#</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    notLockedTimeSlotinfo.map(nltsi => {
                                        return <tr>
                                            <td>
                                                <button className={"btn btn-primary"} data-bs-dismiss="modal"
                                                        onClick={() => {
                                                            setFromNotLocked(nltsi.date!, nltsi.shootingRangeNumber!, nltsi.distanceId!, (nltsi.isMorning ?? true))
                                                        }}>{nltsi.date}</button>
                                            </td>
                                            <td>{nltsi.isMorning ? localizedTexts.labelMorning : localizedTexts.labelAfternoon} </td>
                                            <td>{shootingRanges.find(sr => sr.number == nltsi.shootingRangeNumber)?.name}</td>
                                            <td>{distances.find(d => d.id == nltsi.distanceId)?.name ?? nltsi.distanceId}</td>
                                            <td className={"text-end"}>{nltsi.count}</td>
                                        </tr>
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="modal-footer">
                    </div>
                </div>
            </div>
        </div>

    </div>
}

export default EventV2TimeSlotReservation;