/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AliveResponse {
  command?: string | null;
  parameters?: string[] | null;
}

export interface Discipline {
  /** @format int32 */
  id?: number;
  name?: string | null;
  shortName?: string | null;
  /** @format int32 */
  distanceId?: number;
}

export interface Distance {
  /** @format int32 */
  id?: number;
  name?: string | null;
}

/** @format int32 */
export enum EFireKind {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
}

export interface ExerciseAssignment {
  participantInfo: ParticipantInfo;
  /** @format int32 */
  programNr?: number;
  /** @format int32 */
  numberOfRounds?: number;
}

export interface ExerciseAssignmentReport {
  eventName: string | null;
  exerciseAssignments?: Record<string, ParticipantExerciseAssignment[]>;
}

export interface ExerciseResult {
  /** @format uuid */
  id?: string;
  participantNr: string | null;
  /** @format int32 */
  laneNr?: number;
  /** @format int32 */
  programNr?: number;
  programName?: string | null;
  weaponId?: WeaponTypeId;
  /** @format double */
  primaryScore?: number;
  /** @format double */
  secondaryScore?: number | null;
  state?: ExerciseState;
  /** @format int32 */
  firingDistanceId?: number;
  exerciseSequences?: ExerciseSequence[] | null;
  /** @format int32 */
  shotCount?: number;
}

export interface ExerciseSequence {
  /** @format int32 */
  groupNr?: number;
  /** @format int32 */
  numberOfShots?: number;
  /** @format double */
  primarySequenceResult?: number;
  /** @format double */
  secondarySequenceResult?: number | null;
  positionId?: PositionTypeId;
  fireKind?: FireKind;
  /** @format int32 */
  targetIdent?: number;
  exerciseShots?: ExerciseShot[] | null;
  /** @format double */
  bestShot?: number;
}

export interface ExerciseShot {
  /** @format int32 */
  shotNumber?: number;
  /** @format double */
  primaryScore?: number;
  /** @format double */
  secondaryScore?: number | null;
  /** @format date-time */
  timeStamp?: string;
  /** @format float */
  x?: number | null;
  /** @format float */
  y?: number | null;
  /** @format int32 */
  targetPictureId?: number;
  isDemo?: boolean;
  isPractice?: boolean;
  isInnerTen?: boolean;
}

/** @format int32 */
export enum ExerciseState {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
}

/** @format int32 */
export enum FireKind {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
}

export interface JsonNode {
  options?: JsonNodeOptions;
  parent?: JsonNode;
  root?: JsonNode;
}

export interface JsonNodeOptions {
  propertyNameCaseInsensitive?: boolean;
}

export interface LonConfiguration {
  isShooterNrMandatory?: boolean;
  isRepeatShooterNr?: boolean;
  isBestShotEnabled?: boolean;
}

export interface NewShooterReport {
  /** @format int32 */
  laneNr?: number;
  shooterId: string | null;
}

export interface ParticipantExerciseAssignment {
  /** @format int32 */
  programNr?: number;
  firstName?: string | null;
  lastName?: string | null;
  associationNr?: string | null;
  /** @format int32 */
  numberOfRounds?: number;
  /** @format int32 */
  finishedRoundCount?: number;
  /** @format int32 */
  roundNr?: number;
  /** @format int32 */
  laneNr?: number | null;
}

export interface ParticipantInfo {
  /** @format int32 */
  participantNr?: number;
  lastName: string | null;
  firstName: string | null;
  associationNr?: string | null;
  nation?: string | null;
}

/** @format int32 */
export enum PositionTypeId {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
}

export interface RawMessageRecordDto {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  laneNr?: number;
  messageType?: string | null;
  messageAsJson?: string | null;
}

export interface ScreenRegistration {
  /** @format uuid */
  guid?: string;
  /** @format uuid */
  screenId?: string;
  screenName?: string | null;
  shootingRange?: ShootingRange;
}

export interface ShootingProgramDefinition {
  distance: string | null;
  name: string | null;
  /** @format int32 */
  programNr?: number;
  sequenceDefinitions?: ShootingProgramSequenceDefinition[] | null;
  /** @format double */
  primaryMaxScore?: number;
  /** @format double */
  secondaryMaxScore?: number;
  printSighters?: boolean;
  weaponSelectionRequired?: boolean;
}

export interface ShootingProgramSequenceDefinition {
  /** @format int32 */
  numberOfShots?: number;
  programTargetScoringType?: TargetScoringType;
  fireKind: EFireKind;
  printBestShot?: boolean;
}

export interface ShootingRange {
  id?: string | null;
  name?: string | null;
  description?: string | null;
  street?: string | null;
  zipCode?: string | null;
  city?: string | null;
  shootingRangeDisciplines?: ShootingRangeDiscipline[] | null;
}

export interface ShootingRangeDiscipline {
  /** @format int32 */
  laneCount?: number;
  /** @format int32 */
  shootingRangeTypeId?: number;
  /** @format int32 */
  disciplineId?: number;
  disciplineName?: string | null;
  /** @format int32 */
  distanceId?: number;
  /** @format int32 */
  startLaneNumber?: number;
  /** @format int32 */
  endLaneNumber?: number;
  /** @format date-time */
  aliveTimestamp?: string | null;
}

export interface ShootingRangeInterfaceType {
  /** @format int32 */
  id?: number;
  name?: string | null;
}

export interface ShootingRangeType {
  /** @format int32 */
  id?: number;
  name?: string | null;
  description?: string | null;
  shootingRangeInterfaceType?: ShootingRangeInterfaceType;
}

export interface ShotReport {
  /** @format int32 */
  laneNr?: number;
  /** @format date-time */
  timeStamp?: string;
  shooterId: string | null;
  /** @format int32 */
  programNr?: number;
  /** @format double */
  primaryScore?: number;
  /** @format double */
  secondaryScore?: number | null;
  /** @format int32 */
  targetPictureId?: number;
  fireKind?: FireKind;
  isSighting?: boolean;
  /** @format float */
  x?: number;
  /** @format float */
  y?: number;
  /** @format int32 */
  number?: number;
  isInnerTen?: boolean;
  isDemo?: boolean;
  isIllegal?: boolean;
  /** @format int32 */
  calibre?: number;
  /** @format uuid */
  exerciseResultId?: string;
  /** @format int32 */
  groupNr?: number;
}

export interface StringStringKeyValuePair {
  key?: string | null;
  value?: string | null;
}

/** @format int32 */
export enum TargetScoringType {
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
  Value7 = 7,
  Value8 = 8,
  Value9 = 9,
  Value10 = 10,
  Value11 = 11,
  Value12 = 12,
  Value13 = 13,
  Value14 = 14,
  Value15 = 15,
  Value16 = 16,
  Value17 = 17,
  Value18 = 18,
}

export interface TargetState {
  /** @format int32 */
  nr?: number;
  isMemberOnly?: boolean;
  status?: string | null;
  shooterNr?: string | null;
  /** @format int32 */
  programNr?: number;
  /** @format int32 */
  shotNr?: number;
  /** @format int32 */
  fireKind?: number;
}

export interface TargetStatesWithTimestamp {
  /** @format date-time */
  lastUpdate?: string;
  targetStates?: TargetState[] | null;
}

export interface UserCheck {
  name?: string | null;
  isAuthenticated?: boolean;
}

export interface UserData {
  name?: string | null;
  isAuthenticated?: boolean;
  claims?: StringStringKeyValuePair[] | null;
}

export interface VersionInfoReport {
  interfaceVersion?: string | null;
  interfaceType?: string | null;
  connectorVersion?: string | null;
}

/** @format int32 */
export enum WeaponTypeId {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title WinFire.Core
 * @version 1.0
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags Disciplines
     * @name CoreDisciplinesList
     * @request GET:/api/core/Disciplines
     */
    coreDisciplinesList: (params: RequestParams = {}) =>
      this.request<Discipline[], any>({
        path: `/api/core/Disciplines`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Distances
     * @name CoreDistancesList
     * @request GET:/api/core/Distances
     */
    coreDistancesList: (params: RequestParams = {}) =>
      this.request<Distance[], any>({
        path: `/api/core/Distances`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name CoreConnectorManagementModifyRetentionTimeCreate
     * @request POST:/api/core/connector/{shootingRangeId}/{distanceId}/Management/ModifyRetentionTime
     */
    coreConnectorManagementModifyRetentionTimeCreate: (
      shootingRangeId: string,
      distanceId: number,
      query?: {
        /** @format int32 */
        laneNr?: number;
        /** @format int32 */
        retentionTimeMs?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/core/connector/${shootingRangeId}/${distanceId}/Management/ModifyRetentionTime`,
        method: "POST",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name CoreConnectorManagementAddEventCreate
     * @request POST:/api/core/connector/{shootingRangeId}/{distanceId}/Management/AddEvent
     */
    coreConnectorManagementAddEventCreate: (
      shootingRangeId: string,
      distanceId: number,
      query?: {
        eventName?: string;
        lanes?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/core/connector/${shootingRangeId}/${distanceId}/Management/AddEvent`,
        method: "POST",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name CoreConnectorManagementStartEventCreate
     * @request POST:/api/core/connector/{shootingRangeId}/{distanceId}/Management/StartEvent
     */
    coreConnectorManagementStartEventCreate: (
      shootingRangeId: string,
      distanceId: number,
      query?: {
        eventName?: string;
        lanes?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/core/connector/${shootingRangeId}/${distanceId}/Management/StartEvent`,
        method: "POST",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name CoreConnectorManagementStopEventCreate
     * @request POST:/api/core/connector/{shootingRangeId}/{distanceId}/Management/StopEvent
     */
    coreConnectorManagementStopEventCreate: (
      shootingRangeId: string,
      distanceId: number,
      query?: {
        eventName?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/core/connector/${shootingRangeId}/${distanceId}/Management/StopEvent`,
        method: "POST",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name CoreConnectorManagementCleanEventDataCreate
     * @request POST:/api/core/connector/{shootingRangeId}/{distanceId}/Management/CleanEventData
     */
    coreConnectorManagementCleanEventDataCreate: (
      shootingRangeId: string,
      distanceId: number,
      query?: {
        eventName?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/core/connector/${shootingRangeId}/${distanceId}/Management/CleanEventData`,
        method: "POST",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name CoreConnectorManagementClearLogCreate
     * @request POST:/api/core/connector/{shootingRangeId}/{distanceId}/Management/ClearLog
     */
    coreConnectorManagementClearLogCreate: (
      shootingRangeId: string,
      distanceId: number,
      data: number[],
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/core/connector/${shootingRangeId}/${distanceId}/Management/ClearLog`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name CoreConnectorManagementSetIsParticipantNrRequiredCreate
     * @request POST:/api/core/connector/{shootingRangeId}/{distanceId}/Management/SetIsParticipantNrRequired
     */
    coreConnectorManagementSetIsParticipantNrRequiredCreate: (
      shootingRangeId: string,
      distanceId: number,
      data: number[],
      query?: {
        isRequired?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/core/connector/${shootingRangeId}/${distanceId}/Management/SetIsParticipantNrRequired`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name CoreConnectorManagementSetRepeatParticipantNrCmdCreate
     * @request POST:/api/core/connector/{shootingRangeId}/{distanceId}/Management/SetRepeatParticipantNrCmd
     */
    coreConnectorManagementSetRepeatParticipantNrCmdCreate: (
      shootingRangeId: string,
      distanceId: number,
      data: number[],
      query?: {
        isEnabled?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/core/connector/${shootingRangeId}/${distanceId}/Management/SetRepeatParticipantNrCmd`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Management
     * @name CoreConnectorManagementSetWeaponSelectionRequiredCmdCreate
     * @request POST:/api/core/connector/{shootingRangeId}/{distanceId}/Management/SetWeaponSelectionRequiredCmd
     */
    coreConnectorManagementSetWeaponSelectionRequiredCmdCreate: (
      shootingRangeId: string,
      distanceId: number,
      data: number[],
      query?: {
        isRequired?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/core/connector/${shootingRangeId}/${distanceId}/Management/SetWeaponSelectionRequiredCmd`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PublicRangeData
     * @name CorePublicRangeDataTargetStateDetail
     * @request GET:/api/core/{shootingRangeId}/{distanceId}/PublicRangeData/TargetState
     */
    corePublicRangeDataTargetStateDetail: (shootingRangeId: string, distanceId: number, params: RequestParams = {}) =>
      this.request<TargetStatesWithTimestamp, any>({
        path: `/api/core/${shootingRangeId}/${distanceId}/PublicRangeData/TargetState`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PublicRangeData
     * @name CorePublicRangeDataShootingProgramDefinitionDetail
     * @request GET:/api/core/{shootingRangeId}/{distanceId}/PublicRangeData/shootingProgramDefinition
     */
    corePublicRangeDataShootingProgramDefinitionDetail: (
      shootingRangeId: string,
      distanceId: number,
      params: RequestParams = {},
    ) =>
      this.request<ShootingProgramDefinition[], any>({
        path: `/api/core/${shootingRangeId}/${distanceId}/PublicRangeData/shootingProgramDefinition`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PublicRangeData
     * @name CorePublicRangeDataClearLogCreate
     * @request POST:/api/core/{shootingRangeId}/{distanceId}/PublicRangeData/ClearLog
     */
    corePublicRangeDataClearLogCreate: (
      shootingRangeId: string,
      distanceId: number,
      data: number[],
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/core/${shootingRangeId}/${distanceId}/PublicRangeData/ClearLog`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PublicRangeData
     * @name CorePublicRangeDataRequestDemoShotCreate
     * @request POST:/api/core/{shootingRangeId}/{distanceId}/PublicRangeData/RequestDemoShot
     */
    corePublicRangeDataRequestDemoShotCreate: (
      shootingRangeId: string,
      distanceId: number,
      data: number[],
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/core/${shootingRangeId}/${distanceId}/PublicRangeData/RequestDemoShot`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PublicRangeData
     * @name CorePublicRangeDataStartMatchCreate
     * @request POST:/api/core/{shootingRangeId}/{distanceId}/PublicRangeData/StartMatch
     */
    corePublicRangeDataStartMatchCreate: (
      shootingRangeId: string,
      distanceId: number,
      data: number[],
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/core/${shootingRangeId}/${distanceId}/PublicRangeData/StartMatch`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PublicRangeData
     * @name CorePublicRangeDataRequestAbortCreate
     * @request POST:/api/core/{shootingRangeId}/{distanceId}/PublicRangeData/RequestAbort
     */
    corePublicRangeDataRequestAbortCreate: (
      shootingRangeId: string,
      distanceId: number,
      data: number[],
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/core/${shootingRangeId}/${distanceId}/PublicRangeData/RequestAbort`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PublicRangeData
     * @name CorePublicRangeDataEnableDemoModeCreate
     * @request POST:/api/core/{shootingRangeId}/{distanceId}/PublicRangeData/EnableDemoMode
     */
    corePublicRangeDataEnableDemoModeCreate: (
      shootingRangeId: string,
      distanceId: number,
      data: number[],
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/core/${shootingRangeId}/${distanceId}/PublicRangeData/EnableDemoMode`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PublicRangeData
     * @name CorePublicRangeDataDisableDemoModeCreate
     * @request POST:/api/core/{shootingRangeId}/{distanceId}/PublicRangeData/DisableDemoMode
     */
    corePublicRangeDataDisableDemoModeCreate: (
      shootingRangeId: string,
      distanceId: number,
      data: number[],
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/core/${shootingRangeId}/${distanceId}/PublicRangeData/DisableDemoMode`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PublicRangeData
     * @name CorePublicRangeDataLonConfigurationCreate
     * @request POST:/api/core/{shootingRangeId}/{distanceId}/PublicRangeData/LonConfiguration
     */
    corePublicRangeDataLonConfigurationCreate: (
      shootingRangeId: string,
      distanceId: number,
      data: LonConfiguration,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/core/${shootingRangeId}/${distanceId}/PublicRangeData/LonConfiguration`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags PublicRangeData
     * @name CorePublicRangeDataLonConfigurationDetail
     * @request GET:/api/core/{shootingRangeId}/{distanceId}/PublicRangeData/LonConfiguration
     */
    corePublicRangeDataLonConfigurationDetail: (
      shootingRangeId: string,
      distanceId: number,
      params: RequestParams = {},
    ) =>
      this.request<LonConfiguration, any>({
        path: `/api/core/${shootingRangeId}/${distanceId}/PublicRangeData/LonConfiguration`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PublicRangeData
     * @name CorePublicRangeDataVersionDetail
     * @request GET:/api/core/{shootingRangeId}/{distanceId}/PublicRangeData/version
     */
    corePublicRangeDataVersionDetail: (shootingRangeId: string, distanceId: number, params: RequestParams = {}) =>
      this.request<VersionInfoReport, any>({
        path: `/api/core/${shootingRangeId}/${distanceId}/PublicRangeData/version`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PublicRangeData
     * @name CorePublicRangeDataSendCommandCreate
     * @request POST:/api/core/{shootingRangeId}/{distanceId}/PublicRangeData/SendCommand
     */
    corePublicRangeDataSendCommandCreate: (
      shootingRangeId: string,
      distanceId: number,
      data: AliveResponse,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/core/${shootingRangeId}/${distanceId}/PublicRangeData/SendCommand`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags RangeData
     * @name CoreConnectorRangeDataEventRoundsCreate
     * @request POST:/api/core/connector/{shootingRangeId}/{distanceId}/RangeData/event/{eventName}/rounds/{roundNr}
     */
    coreConnectorRangeDataEventRoundsCreate: (
      shootingRangeId: string,
      distanceId: number,
      roundNr: number,
      eventName: string,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/core/connector/${shootingRangeId}/${distanceId}/RangeData/event/${eventName}/rounds/${roundNr}`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags RangeData
     * @name CoreConnectorRangeDataEventReshootCreate
     * @request POST:/api/core/connector/{shootingRangeId}/{distanceId}/RangeData/event/{eventName}/reshoot
     */
    coreConnectorRangeDataEventReshootCreate: (
      shootingRangeId: string,
      distanceId: number,
      eventName: string,
      query?: {
        /** @format int32 */
        laneNr?: number;
        /** @format int32 */
        participantNr?: number;
        /** @format int32 */
        programNr?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/core/connector/${shootingRangeId}/${distanceId}/RangeData/event/${eventName}/reshoot`,
        method: "POST",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags RangeData
     * @name CoreConnectorRangeDataShootingProgramDefinitionCreate
     * @request POST:/api/core/connector/{shootingRangeId}/{distanceId}/RangeData/shootingProgramDefinition
     */
    coreConnectorRangeDataShootingProgramDefinitionCreate: (
      shootingRangeId: string,
      distanceId: number,
      data: ShootingProgramDefinition[],
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/core/connector/${shootingRangeId}/${distanceId}/RangeData/shootingProgramDefinition`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags RangeData
     * @name CoreConnectorRangeDataShootingProgramDefinitionDelete
     * @request DELETE:/api/core/connector/{shootingRangeId}/{distanceId}/RangeData/shootingProgramDefinition
     */
    coreConnectorRangeDataShootingProgramDefinitionDelete: (
      shootingRangeId: string,
      distanceId: number,
      data: ShootingProgramDefinition[],
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/core/connector/${shootingRangeId}/${distanceId}/RangeData/shootingProgramDefinition`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags RangeData
     * @name CoreConnectorRangeDataShootingProgramDefinitionCreate2
     * @request POST:/api/core/connector/{shootingRangeId}/{distanceId}/RangeData/shootingProgramDefinition/{reportId}
     * @originalName coreConnectorRangeDataShootingProgramDefinitionCreate
     * @duplicate
     */
    coreConnectorRangeDataShootingProgramDefinitionCreate2: (
      shootingRangeId: string,
      reportId: string,
      distanceId: string,
      data: ShootingProgramDefinition[],
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/core/connector/${shootingRangeId}/${distanceId}/RangeData/shootingProgramDefinition/${reportId}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags RangeData
     * @name CoreConnectorRangeDataEventAssignmentsDetail
     * @request GET:/api/core/connector/{shootingRangeId}/{distanceId}/RangeData/event/{eventName}/assignments
     */
    coreConnectorRangeDataEventAssignmentsDetail: (
      shootingRangeId: string,
      distanceId: number,
      eventName: string,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/core/connector/${shootingRangeId}/${distanceId}/RangeData/event/${eventName}/assignments`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags RangeData
     * @name CoreConnectorRangeDataParticipantExerciseAssignmentsCreate
     * @request POST:/api/core/connector/{shootingRangeId}/{distanceId}/RangeData/participantExerciseAssignments/{reportId}
     */
    coreConnectorRangeDataParticipantExerciseAssignmentsCreate: (
      shootingRangeId: string,
      reportId: string,
      distanceId: string,
      data: ExerciseAssignmentReport,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/core/connector/${shootingRangeId}/${distanceId}/RangeData/participantExerciseAssignments/${reportId}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags RangeData
     * @name CoreConnectorRangeDataParticipantExerciseAssignmentsDetail
     * @request GET:/api/core/connector/{shootingRangeId}/{distanceId}/RangeData/participantExerciseAssignments/{reportId}
     */
    coreConnectorRangeDataParticipantExerciseAssignmentsDetail: (
      shootingRangeId: string,
      reportId: string,
      distanceId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/core/connector/${shootingRangeId}/${distanceId}/RangeData/participantExerciseAssignments/${reportId}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags RangeData
     * @name CoreConnectorRangeDataParticipantExerciseAssignmentsCreate2
     * @request POST:/api/core/connector/{shootingRangeId}/{distanceId}/RangeData/participantExerciseAssignments
     * @originalName coreConnectorRangeDataParticipantExerciseAssignmentsCreate
     * @duplicate
     */
    coreConnectorRangeDataParticipantExerciseAssignmentsCreate2: (
      shootingRangeId: string,
      distanceId: number,
      data: ExerciseAssignmentReport,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/core/connector/${shootingRangeId}/${distanceId}/RangeData/participantExerciseAssignments`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags RangeData
     * @name CoreConnectorRangeDataEventAssignmentCreate
     * @request POST:/api/core/connector/{shootingRangeId}/{distanceId}/RangeData/event/{eventName}/assignment
     */
    coreConnectorRangeDataEventAssignmentCreate: (
      shootingRangeId: string,
      eventName: string,
      distanceId: number,
      data: ExerciseAssignment,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/core/connector/${shootingRangeId}/${distanceId}/RangeData/event/${eventName}/assignment`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags RangeData
     * @name CoreConnectorRangeDataAliveCreate
     * @request POST:/api/core/connector/{shootingRangeId}/{distanceId}/RangeData/alive
     */
    coreConnectorRangeDataAliveCreate: (
      shootingRangeId: string,
      distanceId: number,
      query?: {
        isDisconnect?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/core/connector/${shootingRangeId}/${distanceId}/RangeData/alive`,
        method: "POST",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ScreenRegistration
     * @name CoreScreenRegistrationCreate
     * @request POST:/api/core/ScreenRegistration
     */
    coreScreenRegistrationCreate: (data: ScreenRegistration, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/core/ScreenRegistration`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ShootingRangeTypes
     * @name CoreShootingRangeTypesList
     * @request GET:/api/core/ShootingRangeTypes
     */
    coreShootingRangeTypesList: (params: RequestParams = {}) =>
      this.request<ShootingRangeType[], any>({
        path: `/api/core/ShootingRangeTypes`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ShootingRanges
     * @name CoreShootingRangesList
     * @request GET:/api/core/ShootingRanges
     */
    coreShootingRangesList: (params: RequestParams = {}) =>
      this.request<ShootingRange[], any>({
        path: `/api/core/ShootingRanges`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ShootingRanges
     * @name CoreShootingRangesCreate
     * @request POST:/api/core/ShootingRanges
     */
    coreShootingRangesCreate: (data: ShootingRange, params: RequestParams = {}) =>
      this.request<ShootingRange, any>({
        path: `/api/core/ShootingRanges`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ShootingRanges
     * @name CoreShootingRangesDetail
     * @request GET:/api/core/ShootingRanges/{rangeId}
     */
    coreShootingRangesDetail: (rangeId: string, params: RequestParams = {}) =>
      this.request<ShootingRange, any>({
        path: `/api/core/ShootingRanges/${rangeId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ShootingRanges
     * @name CoreShootingRangesNewdistanceCreate
     * @request POST:/api/core/ShootingRanges/{rangeId}/newdistance
     */
    coreShootingRangesNewdistanceCreate: (rangeId: string, data: ShootingRangeDiscipline, params: RequestParams = {}) =>
      this.request<ShootingRange, any>({
        path: `/api/core/ShootingRanges/${rangeId}/newdistance`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ShotData
     * @name CoreConnectorShotDataShotReportCreate
     * @request POST:/api/core/connector/{shootingRangeId}/{distanceId}/ShotData/shotReport
     */
    coreConnectorShotDataShotReportCreate: (
      shootingRangeId: string,
      distanceId: number,
      data: ShotReport,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/core/connector/${shootingRangeId}/${distanceId}/ShotData/shotReport`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ShotData
     * @name CoreConnectorShotDataNewShooterReportCreate
     * @request POST:/api/core/connector/{shootingRangeId}/{distanceId}/ShotData/newShooterReport
     */
    coreConnectorShotDataNewShooterReportCreate: (
      shootingRangeId: string,
      distanceId: number,
      data: NewShooterReport,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/core/connector/${shootingRangeId}/${distanceId}/ShotData/newShooterReport`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ShotData
     * @name CoreConnectorShotDataExerciseResultReportCreate
     * @request POST:/api/core/connector/{shootingRangeId}/{distanceId}/ShotData/exerciseResultReport
     */
    coreConnectorShotDataExerciseResultReportCreate: (
      shootingRangeId: string,
      distanceId: number,
      data: ExerciseResult,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/core/connector/${shootingRangeId}/${distanceId}/ShotData/exerciseResultReport`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ShotData
     * @name CoreConnectorShotDataRawMessageCreate
     * @request POST:/api/core/connector/{shootingRangeId}/{distanceId}/ShotData/rawMessage
     */
    coreConnectorShotDataRawMessageCreate: (
      shootingRangeId: string,
      distanceId: number,
      data: Record<string, JsonNode>,
      query?: {
        /** @format int32 */
        laneNr?: number;
        msgType?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/core/connector/${shootingRangeId}/${distanceId}/ShotData/rawMessage`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ShotData
     * @name CoreConnectorShotDataRawMessageBulkCreate
     * @request POST:/api/core/connector/{shootingRangeId}/{distanceId}/ShotData/rawMessageBulk
     */
    coreConnectorShotDataRawMessageBulkCreate: (
      shootingRangeId: string,
      distanceId: number,
      data: RawMessageRecordDto[],
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/core/connector/${shootingRangeId}/${distanceId}/ShotData/rawMessageBulk`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name CoreUserLoginList
     * @request GET:/api/core/User/login
     */
    coreUserLoginList: (
      query?: {
        returnUrl?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/core/User/login`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name PublicCoreLogoutList
     * @request GET:/api/public/core/logout
     */
    publicCoreLogoutList: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/public/core/logout`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name CoreUserCheckList
     * @request GET:/api/core/User/check
     */
    coreUserCheckList: (params: RequestParams = {}) =>
      this.request<UserCheck, any>({
        path: `/api/core/User/check`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name CoreUserDataList
     * @request GET:/api/core/User/data
     */
    coreUserDataList: (params: RequestParams = {}) =>
      this.request<UserData, any>({
        path: `/api/core/User/data`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
}
