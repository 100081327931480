import LocalizedStrings from "react-localization";

const localizedTexts  = new LocalizedStrings({
    "de" : {
        "title" : "Finanzübersicht",
        "labelInvoiceCount":"Anzahl Rechnungen",
        "labelInvoiceTotalAmount":"Total Rechnungsbetrag",
        "labelOpenInvoiceCount":"Unbezahlte Rechnungen",
        "labelIncomingPayments":"Eingegangene Zahlungen",
        "errorGeneralText":"Ein Fehler ist aufgetreten. Bitte nochmals versuchen."
    },
    "fr": {

    },
    "it": {

    },
    "en": {

    }
});
export default localizedTexts;