import LocalizedStrings from "react-localization";

const localizedTexts  = new LocalizedStrings({
    "de" : {
        "title" : "Rangieren",
        "labelSelection":"Auswahl",
        "labelShootingRange":"Schiessstand",
        "labelDate":"Datum",
        "labelOrganization": "Verein",
        "labelDistance":"Distanz",
        "labelFrom":"Von",
        "labelResetState":"Status reset",
        "labelTo":"Nach",
        "labelToFree":"Nach Freie",
        "labelSwitchEntries":"Tauschen",
        "labelClear":"Abbrechen",
        "labelMorning":"Morgens",
        "labelAllDay": "Ganzer Tag",
        "labelAfternoon":"Nachmittags",
        "labelReload":"DatenNeuLaden",
        "labelLockOrganization":"Vereinsrangeure fixieren",
        "labelSelectOrganizationSlots":"Vereinsrangeure auswählen",
        "labelShowNotLockedOverview":"Übersicht nicht fixierte Rangeure",
        "headerLaneNumber":"Scheibe",
        "headerShootingProgramDefinition":"Stich",
        "errorGeneralText":"Ein Fehler ist aufgetreten. Bitte nochmals versuchen."
    },
    "fr": {
    },
    "it": {

    },
    "en": {

    }
});
export default localizedTexts;