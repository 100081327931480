import LocalizedStrings from 'react-localization';

const localizedTexts = new LocalizedStrings({
    "de": {
        "title": "Rechnungsadresse",
        "buttonSubmit": "Speichern",
        "labelCity": "Ort",
        "labelPostalCode": "PLZ",
        "labelStreetNr": "Nr.",
        "labelStreet": "Strasse",
        "labelFirstName": "Vorname",
        "labelLastName": "Nachname",
        "labelIban": "IBAN",
        "labelEMail": "E-Mail"
    },
    "fr": {
        "title": "Adresse de facturation",
        "buttonSubmit": "Enregistrer",
        "labelCity": "lieu",
        "labelPostalCode": "NPA",
        "labelStreetNr": "No.",
        "labelStreet": "rue",
        "labelFirstName": "prénom",
        "labelLastName": "nom",
        "labelIban": "IBAN",
        "labelEMail": "E-Mail"
    },
    "it": {
        "title": "Indirizzo di fatturazione",
        "buttonSubmit": "Salva",
        "labelCity": "Città",
        "labelPostalCode": "CAP",
        "labelStreetNr": "Num.",
        "labelStreet": "Via",
        "labelFirstName": "Nome",
        "labelLastName": "Cognome",
        "labelIban": "IBAN",
        "labelEMail": "E-Mail"
    },
    "en": {
        "title": "Invoice address",
        "buttonSubmit": "Save",
        "labelCity": "City",
        "labelPostalCode": "Postal Code",
        "labelStreetNr": "No.",
        "labelStreet": "Street",
        "labelFirstName": "First Name",
        "labelLastName": "Last Name",
        "labelIban": "IBAN",
        "labelEMail": "E-Mail"
    }
});

export default localizedTexts;