import {NavLink} from "react-router-dom";
import localizedTexts from "./texts/EventV2FinancialManagement.texts";

const EventV2ShootingRangeManagementNavigation : React.FC = () => {

    return <div className="btn-group py-2" role="group" aria-label="Vertical button group">
        <NavLink to={"financialoverview"} className="btn btn-outline-dark">
            <span className={"bi-book"}> </span>{localizedTexts.financialoverview}
        </NavLink>
        <NavLink to={"invoices"} className="btn btn-outline-dark">
            <span className={"bi-card-list"}> </span>{localizedTexts.invoices}
        </NavLink>
        <NavLink to={"incomingpayments"} className="btn btn-outline-dark">
            <span className={"bi-cash-stack"}> </span>{localizedTexts.incommingpayments}
        </NavLink>
    </div>
}

export default EventV2ShootingRangeManagementNavigation;