import LocalizedStrings from "react-localization";

const localizedTexts  = new LocalizedStrings({
    "de" : {
        "title" : "Zahlungseingänge",
        "importPayments" : "Zahlungen importieren",
        "matchPayments" : "Zahlungen abgleichen",
        "labelSearch" : "Suchen",
        "headerDate" : "Datum",
        "headerAmount" : "Betrag",
        "headerReference" : "Referenz",
        "headerDebitor" : "Debitor",
        "headerActions" : "Aktionen",
        "messageMatchingSuccessful" : "Abgleich erfolgreich.",
        "messageSureDeletePayment" : "Zahlungseingang wirklich löschen?",
        "messagePaymentDeletionSuccessful" : "Zahlungseingang gelöscht.",
        "errorGeneralText":"Ein Fehler ist aufgetreten. Bitte nochmals versuchen."
    },
    "fr": {

    },
    "it": {

    },
    "en": {

    }
});
export default localizedTexts;