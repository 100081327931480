import React, {Component, useEffect, useState} from "react";
import {useParams} from "react-router";
import {PaymentInfo} from "./models/Models";
import {UserData} from "../core/models/Models";
import ApiClientFactory from "./models/ApiClientFactory";
import localizedTexts from "./texts/EventV2IncomingPaymentOverview.texts";
import ToastMessage from "../common/components/ToastMessage";
import UserProfileHandler from "../common/UserProfileHandler";
import PaymentFileUploadModal from "./components/PaymentFileUploadModal";

const EventV2IncomingPaymentOverview = () => {
    const apiClient = ApiClientFactory.GetApiClient();

    const {eventId} = useParams();
    const [userData, setUserData] = useState({} as UserData);

    const [paymentInfos, setPaymentInfos] = useState<PaymentInfo[]>([]);
    const [search, setSearch] = useState<string>("");
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastBgClass, setToastBgClass] = useState('danger');


    useEffect(() => {
        loadData();
        loadUserData();
    }, [])

    async function loadData() {
            const paymentInfos = await apiClient.api.eventsV2FinancialPaymentDetail(`${eventId}`);
            if (paymentInfos.ok) {
                setPaymentInfos(paymentInfos.data);
            }else{
                let errorCodeTranslation = localizedTexts.getString(`errorCode-${paymentInfos.error?.errorCode}`);
                if (!paymentInfos.bodyUsed) {
                    const contentType = paymentInfos.headers.get('Content-Type');
                    if (contentType !== null && contentType.indexOf("application/json") > -1) {
                        paymentInfos.json().then(d => {
                            errorCodeTranslation = localizedTexts.getString(`errorCode-${d?.errorCode}`);
                        });
                    }
                }
                if (!errorCodeTranslation) {
                    errorCodeTranslation = localizedTexts.errorGeneralText ?? "";
                }
                setToastMessage(errorCodeTranslation);
                setShowToast(true);
            }
    }

    async function loadUserData() {
        try {
            var userData = await UserProfileHandler.GetUserData();
            if (userData != undefined) {
                setUserData(userData);
            }
        } catch (e: any) {

        }
    }

    async function deletePaymentInfo (paymentInfo: PaymentInfo){
        try {
            const isConfirmed = window.confirm(localizedTexts.messageSureDeletePayment + "\n" + new Date(paymentInfo.date!).toLocaleDateString() + " " + paymentInfo.amount + " CHF" );
            if (isConfirmed) {
                var result = await apiClient.api.eventsV2FinancialPaymentDelete(paymentInfo.id!, `${eventId}`)
                if (result.ok) {
                    setToastBgClass("success");
                    setToastMessage(localizedTexts.messagePaymentDeletionSuccessful ?? "OK");
                    setShowToast(true);

                    await loadData()
                }

            }
        }
        catch (e: any)
        {
            setToastBgClass("danger");
            setToastMessage(localizedTexts.errorGeneralText ?? "");
            setShowToast(true);
        }
    }


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value.toLowerCase());
    }

    const getMatchingButton = () => {
        if (userData && userData.claims && userData.claims.findIndex((c) => c.key == "roles" && c.value == "WinFireSuperAdmin") > -1) {
            return <button className={"btn btn-primary"} type={"button"} onClick={async () => {
                const result = await apiClient.api.eventsV2FinancialPaymentExecutematchingUpdate(`${eventId}`);
                if (result.ok) {
                    setShowToast(true)
                    setToastMessage(localizedTexts.messageMatchingSuccessful ?? "OK")
                    setToastBgClass("success");
                }
                else
                {
                    setShowToast(true);
                    setToastMessage(localizedTexts.errorGeneralText ?? "");
                    setToastBgClass("danger");
                }
            }}>
                <span className={"bi-list-check"}></span>&nbsp;&nbsp;{localizedTexts.matchPayments}
            </button>
        }
        return null;
    };

    const getImportPaymentButton = () => {
        if (userData && userData.claims && userData.claims.findIndex((c) => c.key == "roles" && c.value == "WinFireSuperAdmin") > -1) {
            return <button className={"btn btn-primary"} type={"button"} data-bs-toggle="modal" data-bs-target="#paymentFileUploadModal">
                <span className={"bi-upload"}></span>&nbsp;&nbsp;{localizedTexts.importPayments}
            </button>
        }
        return null;
    };

    return <div>
        {showToast && (
            <div id="toast-container" className="position-fixed top-0 start-50 translate-middle-x p-3"
                 style={{zIndex: 11}}>
                <ToastMessage id="errorToast" message={toastMessage} onClose={() => setShowToast(false)} role="alert" bgClass={toastBgClass}/>
            </div>
        )}
        <div className="container" style={{maxWidth: '95%'}}>
            <h1><span className={"bi-cash-stack"}> </span>{localizedTexts.title}</h1>
            <div className={"row"}>
                <div className="col-8 d-flex align-items-center justify-content-end">
                    <span className="h4 mt-2">{localizedTexts.labelSearch}: </span>&nbsp;&nbsp;
                    <input placeholder="suchen" onChange={handleChange}/>
                </div>
                <div className="col-2 d-flex align-items-center justify-content-center">
                    {getMatchingButton()}
                </div>
                <div className="col-2 d-flex align-items-center justify-content-end">
                    {getImportPaymentButton()}
                </div>

            </div>
            {paymentInfos.length > 0 ? (
            <table className={"table table-striped table-responsive"}>
                <thead>
                <tr>
                    <th>{localizedTexts.headerDate}</th>
                    <th className={"text-end"}>{localizedTexts.headerAmount}</th>
                    <th>{localizedTexts.headerReference}</th>
                    <th>{localizedTexts.headerDebitor}</th>
                    <th>{localizedTexts.headerActions}</th>
                </tr>
                </thead>
                <tbody>
                {
                    paymentInfos
                        .filter(paymentInfo => {
                            if (search && search != "") {
                                return paymentInfo.reference?.toString().padStart(21, "0").toLowerCase().includes(search)
                            }
                            return true;
                        })
                        .map((paymentInfo: PaymentInfo) => {
                            return (
                                <tr key={paymentInfo.id} className={`${ paymentInfo.isMatched ? "table-success" : ""} align-middle`}>
                                    <td>{paymentInfo.date ? new Date(paymentInfo.date).toLocaleDateString() : "N/A"}</td>
                                    <td className={"text-end"}>{paymentInfo.amount?.toFixed(2) ?? "N/A"} CHF</td>
                                    <td>{paymentInfo.reference}</td>
                                    <td>{paymentInfo.debitorName}</td>
                                    <td>
                                        {!paymentInfo.isMatched ?
                                            <button className={"btn btn-danger"} onClick={() => deletePaymentInfo(paymentInfo)}>
                                                <span className={"bi-trash3-fill"}></span>
                                            </button>
                                            : <span className={"bi-check-circle fs-4"}></span>
                                        }
                                    </td>
                                </tr>
                            );
                        })
                }
                </tbody>
            </table>
            ) : ""}
        </div>

        <PaymentFileUploadModal
            onUpload={(file) => {
                console.log("Uploading file:", file);
                apiClient.api.eventsV2FinancialPaymentParsefileCreate(`${eventId}`, {file})
                .then(() => {
                        console.log("finished parsing file:");
                })
                .catch((error) => {
                    console.error("Error uploading file:", error);
                });
            }}
            onClose={async () => {
                await loadData();
            }}
        />

    </div>
}

export default EventV2IncomingPaymentOverview;

