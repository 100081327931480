import LocalizedStrings from "react-localization";

const localizedTexts  = new LocalizedStrings({
    "de" : {
        "buttonClose" : "Schliessen",
        "buttonUpload" : "Datei hochladen",
        "labelNoFileSelected" : "keine Datei ausgewählt",
        "labelFileUploadSuccessful" : "Datei erfolgreich hochgeladen",
        "labelFileUploadFailed" : "Datei konnte nicht hochgeladen werden",
        "title" : "Zahlungsdatei importieren",
        "textSelectFile": "camt054 Datei auswählen:",
        "errorGeneralText":"Ein Fehler ist aufgetreten. Bitte nochmals versuchen."
    },
    "fr": {

    },
    "it": {

    },
    "en": {

    }
});
export default localizedTexts