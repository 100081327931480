import React, {Component} from "react";
import CoreNavigation from "./CoreNavigation";
import {ShootingRange, TargetState, LonConfiguration} from "./models/Models";

import localizedTexts from "./texts/RangeSettings.Text";
import {useParams} from "react-router";
import ApiClientFactory from "./models/ApiClientFactory";

function RangeSettingsWrapper() {
    let {rangeId} = useParams();
    let {distanceId} = useParams();
    return <RangeSettings rangeId={rangeId} distanceId={parseInt(distanceId!)}/>
}


export class RangeSettings extends Component<RangeSettingsStateProps, RangeSettingsState> {
    apiClient = ApiClientFactory.GetApiClient();

    constructor(props: RangeSettingsStateProps) {
        super(props);
        this.state = {
            shootingRange: undefined,
            isLoading: true,
            isShooterNrMandatory: false,
            isRepeatShooterNr: false,
        }
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        try {
            let range = await this.apiClient.api.coreShootingRangesDetail(`${this.props.rangeId}`);
            if (range.ok) {
                this.setState({shootingRange: range.data, isLoading: false});
            }
            let lonConfig = await this.apiClient.api.corePublicRangeDataLonConfigurationDetail(`${this.props.rangeId}`,  this.props.distanceId)
            if (lonConfig.ok)
            {
                this.setState({isShooterNrMandatory: lonConfig.data.isShooterNrMandatory ?? false, isRepeatShooterNr: lonConfig.data.isRepeatShooterNr ?? false})
            }
        } catch (e: any) {

        }
    }

    enableDemoMode = async (rangeId : string, distanceId : number)=>     {
        try {
            var result = await this.apiClient.api.corePublicRangeDataEnableDemoModeCreate(rangeId ?? "", distanceId, []);
            if (result.ok) {

            }
        } catch (e: any) {
            alert("Error on sending EnableDemoMode");
        }
    }

    disableDemoMode = async (rangeId : string, distanceId : number)=>     {
        try {
            var result = await this.apiClient.api.corePublicRangeDataDisableDemoModeCreate(rangeId ?? "", distanceId, []);
            if (result.ok) {

            }
        } catch (e: any) {
            alert("Error on sending DisableDemoMode");
        }
    }

    setLonConfiguration = async (rangeId: string, distanceId : number, lonConfiguration : LonConfiguration) => {
        try {
            var result = await this.apiClient.api.corePublicRangeDataLonConfigurationCreate(rangeId ?? "", distanceId, lonConfiguration);
            if (result.ok) {

            }
        }
        catch (e: any) {
            alert("Error on sending LonConfiguration");
        }
    }

    handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { id, checked } = event.target;
        this.setState({ [id]: checked } as any);
    };

    private isManualRange = (): boolean =>
    {
        var currentDiscipline = this.state.shootingRange?.shootingRangeDisciplines?.find(discipline => discipline.distanceId == this.props.distanceId);
        if (currentDiscipline?.shootingRangeTypeId === 1 || currentDiscipline?.shootingRangeTypeId === 2)
        {
            return true;
        }
        return false;
    }

    private isLonRange = (): boolean =>
    {
        var currentDiscipline = this.state.shootingRange?.shootingRangeDisciplines?.find(discipline => discipline.distanceId == this.props.distanceId);
        if (currentDiscipline?.shootingRangeTypeId === 6 || currentDiscipline?.shootingRangeTypeId === 7
            || currentDiscipline?.shootingRangeTypeId === 9 ||  currentDiscipline?.shootingRangeTypeId === 10
            || currentDiscipline?.shootingRangeTypeId === 13)
        {
            return true;
        }
        return false;
    }

    render()      {
        if (this.state.isLoading){
            return <div>
                <h1>{localizedTexts.title}</h1>
                <h4>{localizedTexts.textSiteLoading}</h4>
            </div>
        }

    return <div className={"container-fluid"}>
        <h1>{localizedTexts.settings} {this.state.shootingRange?.name} {this.props.distanceId === 1 ? "300m" : this.props.distanceId === 2 ? "50m" : this.props.distanceId === 3 ? "25m" : "10m"}</h1>

        <p hidden={!this.isManualRange()}>{localizedTexts.nosettingsmanuelmode}</p>

        <div className={"card mt-3"} hidden={!this.isLonRange()}>
            <div className={"card-body"}>
                <h5 className={"card-text"}> Demo Mode </h5>
                <button className={"btn btn-primary"}
                        onClick={() => {
                            this.enableDemoMode(this.props.rangeId ?? "", this.props.distanceId)
                        }}
                        hidden={!this.isLonRange()}>{localizedTexts.enableDemoMode}
                </button>
                &nbsp;
                <button className={"btn btn-primary"}
                        onClick={() => {
                            this.disableDemoMode(this.props.rangeId ?? "", this.props.distanceId)
                        }}
                        hidden={!this.isLonRange()}>{localizedTexts.disableDemoMode}
                </button>
            </div>
        </div>

        <div className={"card mt-3"} hidden={!this.isLonRange()}>
            <div className={"card-body"}>
                <p className={"card-text"}> {localizedTexts.eventSettingsDescription} </p>
                <div className="form-check form-switch">
                    <input className="form-check-input"
                           type="checkbox"
                           id="isShooterNrMandatory"
                           checked={this.state.isShooterNrMandatory}
                           onChange={(e) => this.handleCheckboxChange(e)}
                    />
                    <label className="form-check-label" htmlFor="isShooterNrMandatory">{localizedTexts.shooterNrMandatory}</label>
                </div>
                <div className="form-check form-switch">
                    <input className="form-check-input"
                           type="checkbox"
                           id="isRepeatShooterNr"
                           checked={this.state.isRepeatShooterNr}
                           onChange={(e) => this.handleCheckboxChange(e)}
                    />
                    <label className="form-check-label" htmlFor="isRepeatShooterNr">{localizedTexts.shooterNrRepeated}</label>
                </div>
                <button className={"btn btn-primary mt-3"}
                        onClick={() => {
                            const lonConfiguration: LonConfiguration = {
                                isBestShotEnabled : true,
                                isRepeatShooterNr : this.state.isRepeatShooterNr,
                                isShooterNrMandatory : this.state.isShooterNrMandatory,
                            };
                            this.setLonConfiguration(this.props.rangeId ?? "", this.props.distanceId, lonConfiguration)
                        }}
                        hidden={!this.isLonRange()}>{localizedTexts.save}
                </button>
            </div>
        </div>
    </div>

    }
}

interface RangeSettingsState {
    isLoading: boolean;
    shootingRange: ShootingRange | undefined;
    isShooterNrMandatory: boolean;
    isRepeatShooterNr: boolean;
}

interface RangeSettingsStateProps {
    rangeId: string | undefined
    distanceId: number
}

export default RangeSettingsWrapper;