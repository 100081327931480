import {Component, useEffect, useState} from "react";
import {useParams} from "react-router";
import {FinancialInfo} from "./models/Models";
import ApiClientFactory from "./models/ApiClientFactory";
import localizedTexts from "./texts/EventV2FinancialOverview.texts";
import ToastMessage from "../common/components/ToastMessage";

const EventV2FinancialOverview = () => {
    const apiClient = ApiClientFactory.GetApiClient();

    const {eventId} = useParams();

    const [financialInfos, setFinancialInfos] = useState<FinancialInfo|undefined>(undefined);

    const [showToast, setShowToast] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        loadData();
    }, [])

    async function loadData() {
            var financialInfo = await apiClient.api.eventsV2FinancialDetail(`${eventId}`);
            if (financialInfo.ok) {
                setFinancialInfos(financialInfo.data);
            }else{
                setFinancialInfos(undefined);
                let errorCodeTranslation = localizedTexts.getString(`errorCode-${financialInfo.error?.errorCode}`);
                if (!financialInfo.bodyUsed) {
                    const contentType = financialInfo.headers.get('Content-Type');
                    if (contentType !== null && contentType.indexOf("application/json") > -1) {
                        financialInfo.json().then(d => {
                            errorCodeTranslation = localizedTexts.getString(`errorCode-${d?.errorCode}`);
                        });
                    }
                }
                if (!errorCodeTranslation) {
                    errorCodeTranslation = localizedTexts.errorGeneralText ?? "";
                }
                setErrorMessage(errorCodeTranslation);
                setShowToast(true);
            }
    }

    return <div>
        {showToast && (
            <div id="toast-container" className="position-fixed top-0 start-50 translate-middle-x p-3"
                 style={{zIndex: 11}}>
                <ToastMessage id="errorToast" message={errorMessage} onClose={() => setShowToast(false)} role="alert"/>
            </div>
        )}
        <h1>{localizedTexts.title}</h1>
        {financialInfos?
            (
                <table className={"table table-striped table-responsive"}>
                    <tbody>
                    <tr>
                        <td>{localizedTexts.labelInvoiceCount}</td>
                        <td>{financialInfos?.invoiceCount}</td>
                    </tr>
                    <tr>
                        <td>{localizedTexts.labelInvoiceTotalAmount}</td>
                        <td>{financialInfos?.totalInvoiceAmount}</td>
                    </tr>
                    <tr>
                        <td>{localizedTexts.labelOpenInvoiceCount}</td>
                        <td>{financialInfos?.openInvoiceCount}</td>
                    </tr>
                    <tr>
                        <td>{localizedTexts.labelIncomingPayments}</td>
                        <td>{financialInfos?.totalPaidAmount}</td>
                    </tr>
                    </tbody>
                </table>
            )
            : ""
        }
    </div>

}

export default EventV2FinancialOverview;

