import React, { useState, ChangeEvent, FormEvent } from 'react';
import localizedTexts from "../texts/AddressForm.texts";
import {AddressWithIban} from "../models/Models";

interface AddressFormProps {
    initialAddress: AddressWithIban | undefined;
    onSubmit: (address: AddressWithIban) => void;
}

const AddressFormModal: React.FC<AddressFormProps> = ({ initialAddress, onSubmit }) => {
  const [address, setAddress] = useState<AddressWithIban|undefined>(initialAddress);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setAddress((prevAddress) => ({
      ...prevAddress,
      [name]: value
    }));
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
      if (address) {
          onSubmit(address);
      } else {
          console.error("Address is undefined, cannot submit.");
      }
  };

  return (
      <div className="modal fade"
           id="addressFormModal"
           tabIndex={-1}
           aria-labelledby="addressFormModallLabel"
           aria-hidden="true">
          <div className="modal-dialog">
              <div className="modal-content">

                  <div className="modal-header">
                      <h5 className="modal-title" id="paymentFileUploadModalLabel">{localizedTexts.title}</h5>
                      <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                      ></button>
                  </div>

                  <div className="modal-body">
                      <form onSubmit={handleSubmit} className="container-flex">
                          <div className={"row mb-1"}>
                              <div className={"col-6"}>
                                  <input
                                      type="text"
                                      className="form-control"
                                      id="lastName"
                                      name="lastName"
                                      placeholder={localizedTexts.labelLastName}
                                      value={address?.lastName ?? ""}
                                      onChange={handleChange}
                                  />
                              </div>

                              <div className={"col-6"}>
                                  <input
                                      type="text"
                                      className="form-control"
                                      id="firstName"
                                      name="firstName"
                                      placeholder={localizedTexts.labelFirstName}
                                      value={address?.firstName ?? ""}
                                      onChange={handleChange}
                                  />
                              </div>
                          </div>

                          <div className={"row mb-1"}>
                              <div className={"col-8"}>
                                  <input
                                      type="text"
                                      className="form-control"
                                      id="street"
                                      name="street"
                                      placeholder={localizedTexts.labelStreet}
                                      value={address?.street ?? ""}
                                      onChange={handleChange}
                                  />
                              </div>

                              <div className={"col-4"}>
                                  <input
                                      type="text"
                                      className="form-control"
                                      id="streetNr"
                                      name="streetNr"
                                      placeholder={localizedTexts.labelStreetNr}
                                      value={address?.streetNr ?? ""}
                                      onChange={handleChange}
                                  />
                              </div>
                          </div>

                          <div className={"row mb-1"}>
                              <div className={"col-3"}>
                                  <input
                                      type="text"
                                      className="form-control"
                                      id="zipCode"
                                      name="zipCode"
                                      placeholder={localizedTexts.labelPostalCode}
                                      value={address?.zipCode ?? ""}
                                      onChange={handleChange}
                                  />
                              </div>

                              <div className={"col-6"}>
                                  <input
                                      type="text"
                                      className="form-control"
                                      id="city"
                                      name="city"
                                      placeholder={localizedTexts.labelCity}
                                      value={address?.city ?? ""}
                                      onChange={handleChange}
                                  />
                              </div>
                          </div>
                          <div className={"row mb-1"}>
                              <div className={"col-9"}>
                                  <input
                                      type="text"
                                      className="form-control"
                                      id="email"
                                      name="email"
                                      placeholder={localizedTexts.labelEMail}
                                      value={address?.email ?? ""}
                                      onChange={handleChange}
                                  />
                              </div>
                          </div>
                          <div className={"row"}>
                              <div className={"col-9"}>
                                  <input
                                      type="text"
                                      className="form-control"
                                      id="iban"
                                      name="iban"
                                      placeholder={localizedTexts.labelIban}
                                      value={address?.iban ?? ""}
                                      onChange={handleChange}
                                  />
                              </div>
                              <div className={"col-2"}>
                                      <button type="submit" className="btn btn-primary" data-bs-dismiss="modal">{localizedTexts.buttonSubmit}</button>
                              </div>
                          </div>
                      </form>
                  </div>
              </div>
          </div>
      </div>
      );
  };

  export default AddressFormModal;