import {Routes} from "react-router";
import {Route} from "react-router-dom";
import React, {Fragment} from "react";
import EventV2Navigation from "./navigations/EventV2Navigation";
import EventV2DetailView from "./EventV2DetailView";
import EventV2OrganizationManagementNavigation  from "./navigations/EventV2OrganizationManagementNavigation";
import EventV2OrganisationOverview from "./EventV2OrganizationOverview";
import EventV2OrganisationDetailView from "./EventV2OrganisationDetailView";
import EventV2TimeSlotReservation from "./EventV2TimeSlotReservation";
import EventV2ShootingRangeManagementNavigation from "./navigations/EventV2ShootingRangeManagementNavigation";
import EventV2ShootingRangeTimeSlotOverview from "./EventV2ShootingRangeTimeSlotOverview";
import EventV2FinancialManagementNavigation from "./navigations/EventV2FinancialManagementNavigation";
import EventV2FinancialOverview from "./EventV2FinancialOverview";
import EventV2InvoiceOverview from "./EventV2InvoiceOverview";
import EventV2IncomingPaymentOverview from "./EventV2IncomingPaymentOverview";

const EventV2MainView : React.FC = () => {

    return <div className={"container-fluid"}>
        <div className={"row"}>
            <div>
                <Routes>
                    <Route path={"/:eventId/*"} element={<EventV2Navigation/>}/>
                </Routes>
            </div>
            <div>
                <Routes>
                    <Route path={"/:eventId/organisationmangement/*"} element={<EventV2OrganizationManagementNavigation/>}/>
                </Routes>
            </div>

            <div>
                <Routes>
                    <Route path={"/:eventId/shootingrangemanagement/*"} element={<EventV2ShootingRangeManagementNavigation/>}/>
                </Routes>
            </div>
            <div>
                <Routes>
                    <Route path={"/:eventId/financialmanagement/*"} element={<EventV2FinancialManagementNavigation/>}/>
                </Routes>
            </div>
            <br/>
            <div className={"row"}>
                <Fragment>
                    <Routes>
                        <Route path={"/:eventId/overview"} element={<EventV2DetailView/>}/>
                        <Route path={"/:eventId/shootingrangemanagement/"} element={<EventV2ShootingRangeTimeSlotOverview/>}/>
                        <Route path={"/:eventId/shootingrangemanagement/timeslotreservation"} element={<EventV2TimeSlotReservation/>}/>
                        <Route path={"/:eventId/shootingrangemanagement/timeslotreservationoverview"} element={<EventV2ShootingRangeTimeSlotOverview/>}/>
                    </Routes>
                    <Routes>
                        <Route path={"/:eventId/organisationmangement/*"} element={<EventV2OrganisationOverview/>}/>
                        <Route path={"/:eventId/organisationmangement/organisationoverview"} element={<EventV2OrganisationOverview/>}/>
                        <Route path={"/:eventId/organisationmangement/organisationdetail/:requestedOrganizationNumber"} element={<EventV2OrganisationDetailView/>}/>
                    </Routes>
                    <Routes>
                        <Route path={"/:eventId/financialmanagement/"} element={<EventV2FinancialOverview/>}/>
                        <Route path={"/:eventId/financialmanagement/financialoverview"} element={<EventV2FinancialOverview/>}/>
                        <Route path={"/:eventId/financialmanagement/invoices"} element={<EventV2InvoiceOverview/>}/>
                        <Route path={"/:eventId/financialmanagement/incomingpayments"} element={<EventV2IncomingPaymentOverview/>}/>
                    </Routes>
                </Fragment>

            </div>
        </div>
    </div>
}

export default EventV2MainView;