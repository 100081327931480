import localizedTexts from "./texts/RangeAdministrationEditRangeSettingsModal.Text";
import React, {ChangeEvent} from "react";
import {AliveResponse, ShootingRange, ShootingRangeDiscipline, ShootingRangeType, UserData} from "./models/Models";

export default class RangeAdministrationEditRangeSettingsModal extends React.Component<RangeAdministrationNewRangeModalSettingsProps, RangeAdministrationNewRangeSettingsModalState> {

    constructor(props: RangeAdministrationNewRangeModalSettingsProps) {
        super(props);
        this.state = {
            name: props.shootingRange?.name ?? "",
            street: props.shootingRange?.street ?? "",
            city: props.shootingRange?.city ?? "",
            description: props.shootingRange?.description ?? "",
            zipCode: props.shootingRange?.zipCode ?? "",
            id: props.shootingRange?.id ?? "",
            newid: props.shootingRange?.id ?? "",
            isSaving: false,
            interfaceDropdown: "LON",
            systemDropdown: "Production",
            selectedSection: undefined,
        }
    }

    componentDidMount() {
        this.setState({
            selectedSection: 'section1',
        })
    }

    componentDidUpdate(prevProps: RangeAdministrationNewRangeModalSettingsProps) {
        if (this.props.shootingRange !== prevProps.shootingRange) {
            this.setState({
                name: this.props.shootingRange?.name ?? "",
                street: this.props.shootingRange?.street ?? "",
                city: this.props.shootingRange?.city ?? "",
                description: this.props.shootingRange?.description ?? "",
                zipCode: this.props.shootingRange?.zipCode ?? "",
                id: this.props.shootingRange?.id ?? "",
                newid: this.props.shootingRange?.id ?? ""
            });
        }
    }

    handleEnvironmentChange = async ()  =>
    {
        if (this.state.systemDropdown != undefined)
        {
            const aliveResponse: AliveResponse = {
                command: 'Environment',
                parameters: [this.state.systemDropdown]
            };
            let result = await this.props.apiClient.api.corePublicRangeDataSendCommandCreate(this.state.id, 1, aliveResponse);
            if (result.ok) {
                alert(localizedTexts.alertTextWaitForChange);
            } else {
                alert(localizedTexts.alertTextTransmissionFailed);
            }
        }
    }

    handleInterfaceChange = async ()  =>
    {
        if (this.state.interfaceDropdown != undefined) {
            const aliveResponse: AliveResponse = {
                command: 'Interface',
                parameters: [this.state.interfaceDropdown]
            };
            let result = await this.props.apiClient.api.corePublicRangeDataSendCommandCreate(this.state.id, 1, aliveResponse);
            if (result.ok) {
                alert(localizedTexts.alertTextWaitForChange);
            } else {
                alert(localizedTexts.alertTextTransmissionFailed);
            }
        }
    }

    handleIdChange = async () =>
    {
        if (this.state.id != "")
        {
            const aliveResponse: AliveResponse = {
                command: 'RangeId',
                parameters: [this.state.newid]
            };
            let result = await this.props.apiClient.api.corePublicRangeDataSendCommandCreate(this.state.id, 1, aliveResponse);
            if (result.ok) {
                alert(localizedTexts.alertTextWaitForChange);
            } else {
                alert(localizedTexts.alertTextTransmissionFailed);
            }
        }
    }

    changeHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
        let stateHelper: any = this.state;
        stateHelper[evt.currentTarget.id] = evt.currentTarget.value;
        this.setState(stateHelper);
    }

    handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let stateHelper: any = this.state;
        stateHelper["selectedSection"] = event.currentTarget.value;
        this.setState(stateHelper);
    };

    selectChangeHandler = (evt: React.ChangeEvent<HTMLSelectElement>) => {
        const {id, value} = evt.currentTarget;
        this.setState(prevState => ({
            ...prevState,
            [id]: value,
        }) as Pick<RangeAdministrationNewRangeSettingsModalState, keyof RangeAdministrationNewRangeSettingsModalState>);
    };


    render() {
        return <div className="modal fade modal-xl" id={this.props.modalId} tabIndex={-1}
                    aria-labelledby={this.props.modalId}
                    aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id={this.props.modalId + "Label"}>{localizedTexts.title}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className={"shadow p-2 rounded"}>
                            <div className={"row"}>
                                <div className={"col"}>
                                    <h2>{localizedTexts.labelId}: {this.state.id}</h2>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col"}>
                                    <h2>{this.state.name}</h2>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col"}>

                                    <div className="modal-body">
                                        <form id="sectionForm">
                                            <div className="form-group">
                                                <label htmlFor="sectionRadios" className="text-danger mb-2"><i className="bi bi-exclamation-triangle"></i> {localizedTexts.labelSelectOption}</label>
                                                <div id="sectionRadios" className="d-flex align-items-center">
                                                    <div className="form-check mx-3">
                                                        <input className="form-check-input" type="radio" name="section" id="section1Radio" value="section1" onChange={this.handleRadioChange} checked={this.state.selectedSection === 'section1'}/>
                                                        <label className="form-check-label" htmlFor="section1Radio">
                                                            {localizedTexts.lblSystemSwitch}
                                                        </label>
                                                    </div>
                                                    <div className="form-check mx-3">
                                                        <input className="form-check-input" type="radio" name="section" id="section2Radio" value="section2" onChange={this.handleRadioChange} checked={this.state.selectedSection === 'section2'}/>
                                                        <label className="form-check-label" htmlFor="section2Radio">
                                                            {localizedTexts.lblInterfaceSwitch}
                                                        </label>
                                                    </div>
                                                    <div className="form-check mx-3">
                                                        <input className="form-check-input" type="radio" name="section" id="section3Radio" value="section3" onChange={this.handleRadioChange} checked={this.state.selectedSection === 'section3'}/>
                                                        <label className="form-check-label" htmlFor="section3Radio">
                                                            {localizedTexts.lblUpdateSwitch}
                                                        </label>
                                                    </div>
                                                    <div className="form-check mx-3">
                                                        <input className="form-check-input" type="radio" name="section" id="section4Radio" value="section4" onChange={this.handleRadioChange} checked={this.state.selectedSection === 'section4'}
                                                        disabled={this.props.userData?.claims?.findIndex((c) => c.key === "roles" && c.value === "WinFireSuperAdmin") == -1}/>
                                                        <label className="form-check-label" htmlFor="section4Radio">
                                                            {localizedTexts.lblNameSwitch} <i className="bi bi-lock-fill" hidden={this.props.userData?.claims?.findIndex((c) => c.key === "roles" && c.value === "WinFireSuperAdmin") != -1}></i>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            {this.state.selectedSection === 'section1' && (
                                                <div className="section mt-3">
                                                    <div className="form-group">
                                                        <label htmlFor="systemDropdown">{localizedTexts.titleEnvironment}:</label>
                                                        <select
                                                            id="systemDropdown"
                                                            value={this.state.systemDropdown}
                                                            onChange={this.selectChangeHandler}
                                                            className="form-select"
                                                        >
                                                            <option value="Test">{localizedTexts.labelTest}</option>
                                                            <option value="Production">{localizedTexts.labelProd}</option>
                                                            {this.props.userData?.claims?.findIndex((c) => c.key === "roles" && c.value === "WinFireSuperAdmin")? <option value="Local">Lokal</option> : ""}
                                                        </select>
                                                    </div>
                                                    <button type="button" className="btn btn-primary mt-3" onClick={this.handleEnvironmentChange}>{localizedTexts.buttonTriggerChange}</button>
                                                </div>
                                            )}
                                            {this.state.selectedSection === 'section2' && (
                                                <div className="section mt-3">
                                                    <div className="form-group">
                                                        <label htmlFor="interfaceDropdown">Interface:</label>
                                                        <select
                                                            id="interfaceDropdown"
                                                            value={this.state.interfaceDropdown}
                                                            onChange={this.selectChangeHandler}
                                                            className="form-select"
                                                        >
                                                            <option value="LON">LON</option>
                                                            <option value="STYX">STYX</option>
                                                            <option value="TG3000">TG3000</option>
                                                        </select>
                                                        <button type="button" className="btn btn-primary mt-3" onClick={this.handleInterfaceChange}>{localizedTexts.buttonTriggerChange}</button>
                                                    </div>
                                                </div>
                                            )}
                                            {this.state.selectedSection === 'section3' && (
                                                <div className="section mt-3">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value="" id="triggerSoftwareUpdate" checked/>
                                                        <label className="form-check-label" htmlFor="triggerSoftwareUpdate">
                                                            Trigger Software Update
                                                        </label>
                                                    </div>
                                                    <button type="button" className="btn btn-primary mt-3">{localizedTexts.buttonTriggerChange}</button>
                                                </div>
                                            )}
                                            {this.state.selectedSection === 'section4' && (
                                                <div className="section mt-3">
                                                    <div className="form-group">
                                                        <label htmlFor="inputID" className="form-label">ID</label>
                                                        <input type="text" className="form-control" id="inputID" value={this.state.newid} placeholder="Enter ID"  onChange={(e) => this.setState({newid: e.target.value })}/>
                                                    </div>
                                                    <button type="button" className="btn btn-primary mt-3" onClick={this.handleIdChange}>{localizedTexts.buttonTriggerChange}</button>
                                                </div>
                                            )}
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary"
                                    data-bs-dismiss="modal">{localizedTexts.labelClose}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

interface RangeAdministrationNewRangeModalSettingsProps {
    modalId: string;
    shootingRange?: ShootingRange;
    saveHandler: Function;
    apiClient: any;
    userData: UserData|undefined;
}

interface RangeAdministrationNewRangeSettingsModalState {
    id: string;
    name: string;
    description: string;
    street: string;
    zipCode: string;
    city: string;
    isSaving: boolean;
    interfaceDropdown: string;
    systemDropdown: string;
    selectedSection: string | undefined;
    newid: string;
}
