import LocalizedStrings from "react-localization";

const localizedTexts  = new LocalizedStrings({
    "de" : {
        "financialoverview" : "Finanzübersicht",
        "invoices": "Rechnungen",
        "incommingpayments": "Zahlungseingänge"
    },
    "fr": {
    },
    "it": {
    },
    "en": {
    }
});
export default localizedTexts;