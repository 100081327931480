import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import {Discipline, OrganizationWithOverviewInformation} from "./models/Models";
import ApiClientFactory from "./models/ApiClientFactory";
import localizedTexts from "./texts/EventV2OrganisationOverview.texts";
import ToastMessage from "../common/components/ToastMessage";
import OrganizationFilterModal, { FilterOptions } from "./components/OrganizationFilterModal";

const EventV2OrganizationOverview = () => {
    const apiClient = ApiClientFactory.GetApiClient();

    const { eventId } = useParams<{ eventId: string }>();
    const [disciplines, setDisciplines] = useState<Discipline[]>([]);

    const [organizations, setOrganizations] = useState<OrganizationWithOverviewInformation[]>([]);
    const [search, setSearch] = useState<string>("");

    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastBgClass, setToastBgClass] = useState('danger');

    const [filterCriteria, setFilterCriteria] = useState({
        disciplineId: 0,
        group: "",
        unfixedTimeSlotCount: false,
        invoiceStateId: 0
    }); // State for selected filter values


    useEffect(() => {
        loadData();
    }, [])

    async function loadData() {
        try {
            const organizationsResult = await apiClient.api.eventsV2OrganizationsRegisteredOverviewDetail(`${eventId}`);
            if (organizationsResult.ok){
                setOrganizations(organizationsResult.data);
            }
            else
            {
                setToastMessage(localizedTexts.errorGeneralText!);
                setShowToast(true);
            }

            const eventDetailsResponse = await apiClient.api.eventsV2EventInformationRegistrationinformationDetail(eventId ?? "");
            if (eventDetailsResponse.ok) {
                if (eventDetailsResponse.data.disciplines && eventDetailsResponse.data.disciplines.length > 0) {
                    setDisciplines(eventDetailsResponse.data.disciplines);
                }
            }

        }
        catch (e: any) {
            console.error(e);
        }
    }

    const handleApplyFilters = (filters: FilterOptions) => {
        setFilterCriteria((prev) => ({
            ...prev, // Keep existing values
            disciplineId: filters.disciplineId ?? 0,
            group: filters.group ?? "",
            unfixedTimeSlotCount: filters.unfixedTimeSlotCount ?? false,
            invoiceStateId: filters.invoiceStateId ?? 0,
        }));
    };

    const removeFilter = (filterKey: keyof typeof filterCriteria, resetValue: any) => {
        setFilterCriteria((prev) => ({
            ...prev,
            [filterKey]: resetValue,
        }));
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value.toLowerCase());
    }

    return <div>
        {showToast && (
            <div id="toast-container" className="position-fixed top-0 start-50 translate-middle-x p-3"
                 style={{zIndex: 11}}>
                <ToastMessage id="errorToast" message={toastMessage} onClose={() => setShowToast(false)} role="alert" bgClass={toastBgClass}/>
            </div>
        )}
        {organizations.length > 0 && (
            <div className="container" style={{maxWidth: '95%'}}>
                <h1><span className={"bi-card-list"}> </span>{localizedTexts.title}</h1>
                <div className="row">
                    <div className="col-8 d-flex align-items-center">
                        <span className="h4 mt-1">{localizedTexts.labelFilter}: </span>
                        <button type="button" data-bs-toggle="modal" data-bs-target="#OrganizationFilterModal" className="btn btn-link"><span className="bi-funnel-fill" style={{fontSize: "1.5rem"}}></span></button>
                        {filterCriteria.disciplineId > 0 && <span className="text-primary me-2" onClick={() => removeFilter("disciplineId", 0)}>{disciplines.find(d => d.id == filterCriteria.disciplineId)?.name}<i className="bi-x"/></span>}
                        {filterCriteria.unfixedTimeSlotCount && <span className="text-primary me-2" onClick={() => removeFilter("unfixedTimeSlotCount", false)}>{localizedTexts.textUnfixedRangeurs}<i className="bi-x"/></span>}
                        {filterCriteria.invoiceStateId == 2 && <span className="text-primary me-2" onClick={() => removeFilter("invoiceStateId", 0)}>{localizedTexts.textOpenInvoices}<i className="bi-x"/></span>}
                        {filterCriteria.invoiceStateId == 4 && <span className="text-primary me-2" onClick={() => removeFilter("invoiceStateId", 0)}>{localizedTexts.textOverdueInvoices}<i className="bi-x"/></span>}
                    </div>
                    <div className="col-4 d-flex align-items-center justify-content-end">
                        <span className="h4 mt-1">{localizedTexts.labelSearch}: </span>&nbsp;&nbsp;
                        <input placeholder="suchen" onChange={handleSearchChange}/>
                    </div>
                </div>
                <table className={"table table-striped table-responsive"}>
                    <thead>
                    <tr>
                        <th>{localizedTexts.headerOrganization}</th>
                        <th>{localizedTexts.headerOrganizationNumber}</th>
                        <th>{localizedTexts.headerDiscipline}</th>
                        <th>{localizedTexts.headerGroups}</th>
                        <th>{localizedTexts.headerTimeSlot}</th>
                        <th>{localizedTexts.headerInvoices}</th>
                        <th>{localizedTexts.headerLastEmail}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        organizations
                            .filter(o => {
                                const matchesSearch = !search || ((o.organizationName?.toLowerCase().indexOf(search.toLowerCase()) ?? -1) > -1)
                                    || ((o.organizationNumber?.indexOf(search) ?? -1) > -1);

                                const matchesDiscipline = !filterCriteria.disciplineId || o.disciplines?.some(d => d.id == filterCriteria.disciplineId);
                                const matchesTimeSlotState = !filterCriteria.unfixedTimeSlotCount || ((o.unfixedTimeSlotCount ?? 0) > 0 && filterCriteria.unfixedTimeSlotCount)
                                const matchesInvoiceState = (filterCriteria.invoiceStateId == 0) || ((o.openInvoiceCount ?? 0) > 0 && filterCriteria.invoiceStateId == 2) || ((o.overdueInvoiceCount ?? 0) > 0 && filterCriteria.invoiceStateId == 4)

                                return matchesSearch && matchesDiscipline && matchesTimeSlotState && matchesInvoiceState;
                            })
                            .map(organization => {
                            return(
                                <tr key={organization.organizationNumber}>
                                    <td>
                                        <a className="text-decoration-none" href={`/eventv2/${eventId}/organisationmangement/organisationdetail/${organization.organizationNumber}`}>{organization.organizationName}</a>
                                    </td>
                                    <td>
                                        {organization.organizationNumber}
                                    </td>
                                    <td>
                                        {Array.from(new Set(organization.disciplines?.map(d => d.name))).join(", ")}
                                    </td>
                                    <td>
                                        <div>
                                            <span>{organization.groupCount}</span>
                                            {organization.incompleteGroupCount ?? 0 > 0 ? <span style={{color: "orangered"}} className={"ms-1 bi-exclamation-triangle"}> </span> : ""}
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <span>{organization.timeSlotsCount}</span>
                                            {organization.unfixedTimeSlotCount ?? 0 > 0 ? <span style={{color: "orangered"}} className={"ms-1 bi-exclamation-triangle"}> </span> : ""}
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <span>{organization.invoiceCount}</span>
                                            {organization.openInvoiceCount ?? 0 > 0 ? <span style={{color: "blue"}} className={"ms-1 bi-hourglass"}> </span> : ""}
                                            {organization.overdueInvoiceCount ?? 0 > 0 ? <span style={{color: "orangered"}} className={"ms-1 bi-exclamation-triangle"}> </span> : ""}
                                            {organization.payedInvoiceCount ?? 0 > 0 ? <span style={{color: "green"}} className={"ms-1 bi-check-circle"}> </span> : ""}
                                        </div>
                                    </td>
                                    <td>
                                        <div>

                                            <span>{organization.lastEmailSentType}</span>
                                            <span className={"ms-1"}>{organization.lastEmailSent ? `(${new Date(organization.lastEmailSent).toLocaleDateString()})` : ""}</span>
                                        </div>
                                    </td>
                                </tr>
                            );
                            })
                    }
                    </tbody>
                </table>
            </div>
            )}
         <OrganizationFilterModal
             onApplyFilters={handleApplyFilters}
             disciplines={disciplines}
             filterCriteria={filterCriteria}
         />
    </div>
};

export default EventV2OrganizationOverview;

