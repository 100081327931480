import localizedTexts from "./texts/EventV2ShootingRangeTimeSlotOverview.texts";
import ApiClientFactory from "./models/ApiClientFactory";
import {useParams} from "react-router";
import {useEffect, useState} from "react";
import {TimeSlotReservationOverview} from "./models/Models";

const EventV2ShootingRangeTimeSlotOverview : React.FC = () => {
    const apiClient = ApiClientFactory.GetApiClient();
    const {eventId} = useParams();

    const [overviewData, setOverviewData] = useState<TimeSlotReservationOverview[]>([]);

    const [dataLoading, setDataLoading] = useState(true);

    useEffect(() => {
        loadData();
    }, []);

    async function loadData(){
        const result = await apiClient.api.eventsV2ShootingRangeManagementTargetreservationtimeslotsOverviewDetail(`${eventId}`);
        if (result.ok){
            setOverviewData(result.data);
            setDataLoading(false)
        }
    }


    return <div>
        <h1>{localizedTexts.title}</h1>
        {
            dataLoading ?
                <div className={"spinner-border text-warning"} style={{width: "100px", height: "100px"}}></div> :
                <table className={"table table-striped"}>
                    <thead>
                    <tr>
                        <th className={"sticky"}>{localizedTexts.headerShootingRange}</th>
                        <th className={"sticky"}>{localizedTexts.headerDate}</th>
                        <th className={"sticky"}>{localizedTexts.headerDistance}</th>
                        <th className={"sticky"}>{localizedTexts.headerOpenMorning}</th>
                        <th className={"sticky"}>{localizedTexts.headerOpenAfternoon}</th>
                        <th className={"sticky"}>{localizedTexts.headerBooked}</th>
                        <th className={"sticky"}>{localizedTexts.headerBlocked}</th>
                        <th className={"sticky"}>{localizedTexts.headerTotal}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        overviewData.map((od) => {
                            return <tr key={od.shootingRangeName! + od.distanceName! + od.date!}>
                                <td>{od.shootingRangeName}</td>
                                <td>{Intl.DateTimeFormat("DE-CH", {
                                    weekday: 'long',
                                    month: 'long',
                                    day: '2-digit',
                                    year: 'numeric'
                                }).format(Date.parse(od.date!))}</td>
                                <td>{od.distanceName}</td>
                                <td>{od.morningOpenCount}</td>
                                <td>{od.afternoonOpenCount}</td>
                                <td>{od.usedCount}</td>
                                <td>{od.blockedCount}</td>
                                <td>{od.totalCount}</td>
                            </tr>
                        })
                    }
                    </tbody>
                </table>
        }


    </div>
}

export default EventV2ShootingRangeTimeSlotOverview;